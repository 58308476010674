/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table, Tooltip } from 'antd';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import Ellipse from 'components/Global/Ellipse';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteApi } from 'redux/apis';
import {
    ActiveRule,
    CopyActionSvg,
    DeleteRuleActionSvg,
    EditRuleActionSvg,
    InactiveRule,
    QuickbooksEmployeeBlue,
} from 'utils/svgs';
import { checkPermission, toastText } from 'utils/utils';
import styles from './index.module.scss';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
        setActivatorNodeRef,
    } = useSortable({
        id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if ((child as React.ReactElement).key === 'sort') {
                    return React.cloneElement(child as React.ReactElement, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{ touchAction: 'none', cursor: 'move' }}
                                {...listeners}
                            />
                        ),
                    });
                }
                return child;
            })}
        </tr>
    );
};

const DraggableTable = ({
    onChangePriority,
    getCustomRulesData,
    openAddRuleModal,
    data,
    isLoading
}: any) => {
    const { Column } = Table;

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteData, setDeleteData] = useState<any>(null);

    const selectedCompanyPermission = useSelector(
        (state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
    );

    // for check is there add permission
    const isAddRulePermission = checkPermission(selectedCompanyPermission, {
        permissionName: 'Custom Rules',
        permission: ['add'],
    });
    const isEditRulePermission = checkPermission(selectedCompanyPermission, {
        permissionName: 'Custom Rules',
        permission: ['edit'],
    });
    const isDeleteRulePermission = checkPermission(selectedCompanyPermission, {
        permissionName: 'Custom Rules',
        permission: ['delete'],
    });

    const onDragEnd = ({ active, over }: any) => {
        if (active.id !== over?.id) {
            const _data = data;
            const activeIndex = _data.findIndex((i: any) => i.id === active.id);
            const overIndex = _data.findIndex((i: any) => i.id === over?.id);
            const arr = arrayMove(_data, activeIndex, overIndex);
            const finalArr: any[] = [];
            arr.forEach((e: any, index) => {
                finalArr.push({
                    ...e,
                    priority: index + 1,
                });
            });
            onChangePriority(finalArr);
        }
    };

    const deleteHandler = async () => {
        if (deleteData) {
            try {
                const response: any = await deleteApi(
                    `/custom-configuration-rule/${deleteData.id}?companyId=${localStorage.getItem(
                        'companyId'
                    )}`
                );
                setIsDeleteModalOpen(false);
                toastText(response.data.message, 'success');
                getCustomRulesData();
            } catch (err: any) {
                let message = 'Something went wrong in deleting rule.';
                if (err.response.data.message) {
                    message = err.response.data.message;
                }
                setIsDeleteModalOpen(false);
                toastText(message, 'error');
            }
        }
    };

    const deleteModalOpen = (data: any) => {
        setIsDeleteModalOpen(true);
        setDeleteData(data);
    };

    return (
        <>
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext
                    // rowKey array
                    items={data.map((i: any) => i.id)}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        loading={isLoading}
                        components={{
                            body: {
                                row: Row,
                            },
                        }}
                        rowKey="id"
                        scroll={{ y: '60vh' }}
                        dataSource={data}
                        pagination={false}
                    >
                        <Column key="sort" className="bg-white" width="20%" />
                        <Column
                            title="Priority"
                            dataIndex="priority"
                            key="priority"
                            className="bg-white"
                            width="20%"
                        />
                        <Column
                            title="Rule Name"
                            dataIndex="name"
                            key="name"
                            className="bg-white"
                            width="20%"
                            render={(value, data: any) => {
                                return (
																	<div
																		className={styles['draggable_table__rule']}
																	>
																		<span
																			className={
																				styles['draggable_table__rule-employee']
																			}
																			onClick={() => {
																				openAddRuleModal(data);
																			}}
																		>
																			{value}
																		</span>
																		<span
																			onClick={() => {
																				openAddRuleModal(data);
																			}}
																		>
																			<QuickbooksEmployeeBlue />
																		</span>
																	</div>
																);
                            }}
                        />
                        <Column
                            title="Description"
                            dataIndex="description"
                            key="description"
                            className="bg-white"
                            width="20%"
                            render={(value) => (
                                <div className={styles['draggable_table__description']}>
                                    <Ellipse
                                        message={value}
                                        maxLength={300}
                                        isTooltip={true}
                                        tooltipMessage={value}
                                    />
                                </div>
                            )}
                        />
                        <Column
                            title="Status"
                            dataIndex="isActive"
                            key="isActive"
                            className="bg-white"
                            width="20%"
                            render={(value) => (
                                <div className={styles['draggable_table__status']}>
                                    {value ? (
                                        <div className={styles['draggable_table__status-active']}>
                                            <ActiveRule />
                                            <span>Active</span>
                                        </div>
                                    ) : (
                                        <div className={styles['draggable_table__status-inactive']}>
                                            <InactiveRule />
                                            <span>Inactive</span>
                                        </div>
                                    )}
                                </div>
                            )}
                        />
                        {(isAddRulePermission ||
                            isEditRulePermission ||
                            isDeleteRulePermission) && (
                                <Column
                                    title="Actions"
                                    dataIndex="actions"
                                    key="actions"
                                    className="bg-white"
                                    width="20%"
                                    render={(value: string, data: any) => (
                                        <div className={styles['draggable_table__actions']}>
                                            {isAddRulePermission && (
                                                <Tooltip title="Copy Rule">
                                                    <span
                                                        className={styles['draggable_table__actions-copy']}
                                                        onClick={() => {
                                                            openAddRuleModal(data, true);
                                                        }}
                                                    >
                                                        <CopyActionSvg />
                                                    </span>
                                                </Tooltip>
                                            )}
                                            {isEditRulePermission && (
                                                <Tooltip title="Edit Rule">
                                                    <span
                                                        className={styles['draggable_table__actions-edit']}
                                                        onClick={() => {
                                                            openAddRuleModal(data);
                                                        }}
                                                    >
                                                        <EditRuleActionSvg />
                                                    </span>
                                                </Tooltip>
                                            )}
                                            {isDeleteRulePermission && (
                                                <Tooltip title="Delete Rule">
                                                    <span
                                                        className={styles['draggable_table__actions-delete']}
                                                        onClick={() => deleteModalOpen(data)}
                                                    >
                                                        <DeleteRuleActionSvg />
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </div>
                                    )}
                                />
                            )}
                    </Table>
                </SortableContext>
            </DndContext>
            {isDeleteModalOpen && (
                <ConfirmDelete
                    handleCancel={() => {
                        setIsDeleteModalOpen(false);
                        setDeleteData(null);
                    }}
                    handleOk={() => {
                        setIsDeleteModalOpen(false);
                        setDeleteData(null);
                    }}
                    isModalOpen={isDeleteModalOpen}
                    deleteHandler={deleteHandler}
                />
            )}
        </>
    );
};

export default DraggableTable;
