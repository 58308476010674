import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postApi } from 'redux/apis';
import styles from './index.module.scss';
import { toastText } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { fetchProfileAction } from 'redux/action/profileAction';
import { getCompanies, getCompanyDetails, updateCompanyConnect, updateCompanyStatus } from 'redux/slice/companySlice';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'antd';

const QuickbooksCallback = () => {
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const [open, setOpen] = useState(false);

	useEffect(() => {
		const run = async () => {
			setIsLoading(true);

			if (
				localStorage.getItem('isLogin') &&
				localStorage.getItem('isLogin') === 'true'
			) {
				try {
					const machineId = uuidv4();
					postApi('/quickbooks/sso-callback', {
						url: window.location.href,
						isLogin: localStorage.getItem('isLogin') === 'true' ? true : false,
						machineId: machineId,
					})
						.then((response) => {
							localStorage.setItem(
								'accessToken',
								response?.data?.data?.accessToken
							);
							localStorage.setItem(
								'refreshToken',
								response?.data?.data?.refreshToken
							);
							localStorage.removeItem('isLogin');
						})
						.then(() => {
							dispatch(fetchProfileAction() as any)
								.unwrap()
								.then((res: any) => {
									dispatch(getCompanies(res));
									const disConnectRealmId = localStorage.getItem('disconnect-companyId');
									if (disConnectRealmId) {
										let disConnectCompanyId: string | null = null;

										const companyData = res.data.companies.find((com: any) => com?.company?.tenantID === disConnectRealmId);

										disConnectCompanyId = companyData.companyId;

										if (disConnectCompanyId) {
											disconnectCompany(disConnectCompanyId).then(() => {
												localStorage.removeItem('disconnect-companyId');
												localStorage.setItem('settings', 'Integrations');
												navigate('/?openDrawer=true');
											}).catch((err) => {
												console.log(err);
											});
										} else {
											navigate('/');
											setIsLoading(false);
										}
									} else {
										navigate('/');
										setIsLoading(false);
									}
								});
						})
						.catch((err) => {
							setIsLoading(false);
							if (err.response?.data?.message === 'Your QuickBooks email is not verified') {
								setOpen(true);
								return;
							}
							toastText(err.response?.data?.message, 'error');
							localStorage.clear();
							navigate('/login');
						});
				} catch (err: any) {
					setIsLoading(false);
					toastText(err.response?.data?.message, 'error');
					navigate('/login');
				}
			} else if (
				localStorage.getItem('isFromAppInstall') &&
				localStorage.getItem('isFromAppInstall') === 'true'
			) {
				try {
					const machineId = uuidv4();
					postApi('/quickbooks/get-app-now/callback', {
						url: window.location.href,
						machineId: machineId,
						companyId: null,
					})
						.then((response) => {
							localStorage.setItem(
								'accessToken',
								response?.data?.data?.accessToken
							);
							localStorage.setItem(
								'refreshToken',
								response?.data?.data?.refreshToken
							);
							localStorage.removeItem('isFromAppInstall');
						})
						.then(() => {
							dispatch(fetchProfileAction() as any)
								.unwrap()
								.then((res: any) => {
									localStorage.removeItem('companyId');
									localStorage.removeItem('payPeriod');
									dispatch(getCompanies(res));
									navigate('/?openDrawer=true&drawerSelection=Integrations');
									setIsLoading(false);
								})
								.then(() => {
									dispatch(getCompanyDetails(localStorage.getItem('companyId')));
								});
						})
						.catch((err) => {
							setIsLoading(false);
							if (err.response?.data?.message === 'Your QuickBooks email is not verified') {
								setOpen(true);
								return;
							}
							toastText(err.response?.data?.message, 'error');
							// localStorage.clear();
							navigate('/');
						});
				} catch (err: any) {
					setIsLoading(false);
					if (err.response?.data?.message === 'Your QuickBooks email is not verified') {
						setOpen(true);
						return;
					}
					toastText(err.response?.data?.message, 'error');
					navigate('/login');
				}
			} else {
				const isConnectionExisting = localStorage.getItem(
					'isConnectionExisting'
				);

				postApi('/quickbooks/callback', {
					url: window.location.href,
					companyId:
						isConnectionExisting && isConnectionExisting === 'true'
							? localStorage.getItem('companyId') || null
							: null,
				})
					.then(() => {
						setIsLoading(false);
						dispatch(fetchProfileAction())
							.unwrap()
							.then((res) => {
								localStorage.removeItem('companyId');
								localStorage.removeItem('payPeriod');
								dispatch(getCompanies(res));
							})
							.then(() => {
								dispatch(getCompanyDetails(localStorage.getItem('companyId')));
							})
							.then(() => {
								localStorage.setItem('settings', 'Configurations');
								navigate('/?openDrawer=true&createNewPayPeriod=true');
							});
						localStorage.removeItem('qbUrl');
					})
					.catch((err: any) => {
						setIsLoading(false);
						if (
							err?.response?.data?.message === 'Can not connect this company'
						) {
							toast.error(
								'You are trying to connect different companies. Please connect the same company that you have previously connected.',
								{
									style: {
										fontSize: '16px',
									},
									duration: 5000,
								}
							);
							setTimeout(() => {
								window.open(localStorage.getItem('qbUrl') as string, '_self');
							}, 5000);
						} else {
							toastText(err?.response?.data?.message, 'error');
							if (err.response?.data?.message === 'Your QuickBooks email is not verified') {
								setOpen(true);
								return;
							}
							navigate('/');
						}
					});
			}
		};
		run();
	}, []);

	const disconnectCompany = async (companyId: string) => {
		try {
			const res = await postApi('/quickbooks/disconnect', {
				companyId: companyId,
			})

			dispatch(
				updateCompanyConnect({
					companyId: companyId,
					value: false,
				})
			);

			toastText(res?.data?.message, 'success');

			const params = {
				data: {
					key: companyId,
				},
				value: false,
			};

			dispatch(updateCompanyStatus(params));

		} catch (error: any) {
			toastText(error?.response?.data?.message, 'error');
		}
	}

	return (
		<div className={styles['main-container']}>
			{isLoading && <img src="assets/gifs/loading-black.gif" />}
			<Modal
				open={open}
				onCancel={() => {
					localStorage.clear();
					navigate('/'); 
					setOpen(false);
				}}
				footer={false}
			>
				Your email is not yet verified. Please <a href='https://accounts.intuit.com/app/account-manager/security'>login to your intuit account</a>
			</Modal>
		</div>
	);
};

export default QuickbooksCallback;
