import { createSlice } from '@reduxjs/toolkit';
import { fetchClosingDateAction } from 'redux/action/closingDateAction';
const initialState: any = {
    isLoading: true,
    closingDate: null,
    error: null,
};

const closingDateSlice = createSlice({
    initialState,
    name: 'closingDate',
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClosingDateAction.pending, (state) => {
            state.isLoading = true;
            state.closingDate = null;
        });
        builder.addCase(fetchClosingDateAction.fulfilled, (state, action) => {
            state.closingDate = action.payload.data;
            state.isLoading = false;
            state.error = null;
        });
        builder.addCase(fetchClosingDateAction.rejected, (state: any, action) => {
            state.error = action.payload;
            state.closingDate = false;
            state.isLoading = false;
        });
    },
});

export default closingDateSlice;
