import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApi } from 'redux/apis';

export const syncLogsAction = createAsyncThunk(
	'syncLogs/get',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await getApi('/sync-logs', {
				...params,
				companyId: localStorage.getItem('companyId'),
			});
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const syncLogsPaginateAction = createAsyncThunk(
	'syncLogs/paginate',
	async (queryParameters: any, { rejectWithValue }: any) => {
		try {
			const params = {
				...queryParameters,
				companyId: localStorage.getItem('companyId'),
			};

			const response = await getApi('/sync-logs', params);
			return response.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);
