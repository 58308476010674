import React, { useEffect, useState } from 'react';
import SearchAndFilter from '../SearchAndFilter';
import Table from '../Table';
import { useDebounce } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { updateCustomRules } from 'redux/slice/customRulesSlice';
import { AppDispatch } from 'redux/store';
import { putApi } from 'redux/apis';
import { hasText } from 'utils/utils';
import { getEditCustomRulesAction } from 'redux/action/customRuleAction';

interface Props {
	handleOpen: () => void;
	modalData: any;
}

const EditRules = (props: Props) => {
	const { handleOpen, modalData } = props;

	const [searchValue, setSearchValue] = useState<string>('');
	const [debounceSearchValue] = useDebounce(searchValue, 1000);
	const [isSearchable, setIsSearchable] = useState(false);
	const [status, setStatus] = useState<string | null>(null);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	const getCustomRulesData = async () => {
		const query: any = {
			search: searchValue,
			status,
		};

		if (!hasText(searchValue)) {
			delete query.search;
		}

		if (!hasText(status)) {
			delete query.status;
		}

		await dispatch(getEditCustomRulesAction(query));
	};

	useEffect(() => {
		getCustomRulesData();
	}, [selectedCompanyDetails]);

	useEffect(() => {
		if (isSearchable) {
			getCustomRulesData();
		}
	}, [debounceSearchValue, isSearchable, selectedCompanyDetails, status]);

	const dispatch = useDispatch<AppDispatch>();

	const updatePriority = async (data: any) => {
		try {
			await putApi(
				`/custom-rule/update/priority?companyId=${localStorage.getItem(
					'companyId'
				)}`,
				data
			);
		} catch (error) {
			//
			console.log(error);
		}
	};

	return (
		<div>
			<div className={styles['custom-rules-container']}>
				<SearchAndFilter
					performSearchHandler={(value: string) => {
						setSearchValue(value);
						setIsSearchable(true);
					}}
					searchValue={searchValue}
					status={status}
					onChangeStatus={(value: string | null) => {
						setStatus(value);
						setIsSearchable(true);
					}}
					onClearFilter={() => {
						setSearchValue('');
						setStatus(null);
						setIsSearchable(true);
					}}
				/>
			</div>
			<div>
				<Table
					onChangePriority={(data: any) => {
						dispatch(updateCustomRules({ data, key: 'edit' }));
						updatePriority(data);
					}}
					getCustomRulesData={getCustomRulesData}
					openAddRuleModal={handleOpen}
					modalData={modalData}
					tableName="editData"
					tableLoading="editIsLoading"
				/>
			</div>
		</div>
	);
};

export default EditRules;
