import { LoginLayoutBody } from 'components/Login';
import { RegistrationLayout } from 'layouts';
import { FORMDATA } from 'constants/Data';
import { loginAction } from 'redux/slice/loginSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCompanies } from 'redux/slice/companySlice';
import { useEffect, useRef, useState } from 'react';
import { fetchProfileAction } from 'redux/action/profileAction';
import axios from 'axios';
import { invalidText, toastText } from 'utils/utils';

// Login page
const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		// Create a cancel token source
		const cancelTokenSource = axios.CancelToken.source();

		// Cleanup function to cancel pending requests on component unmount or route change
		return () => {
			cancelTokenSource.cancel('Login page is navigating away.');
		};
	}, []);
	const [reCaptchaValue, setReCaptchaValue] = useState(null);
	const recaptchaRef = useRef(null);

	const onSubmit = (values: any) => {
		if (!invalidText(reCaptchaValue)) {
			setIsLoading(true);
			localStorage.clear();
			dispatch(loginAction({ ...values, reCaptchaValue }) as any)
				.unwrap()
				.then(() => {
					dispatch(fetchProfileAction() as any)
						.unwrap()
						.then((res: any) => {
							setIsLoading(false);
							dispatch(getCompanies(res));
							if (res.data.isSuperAdmin) {
								navigate('/admin');
							} else {
								navigate('/');
							}
						});
				})
				.catch(() => {
					setIsLoading(false);
					navigate('/login');
					(recaptchaRef?.current as any)?.reset();
					setReCaptchaValue(null);
				});
		} else {
			toastText('Please verify re-captcha', 'error');
		}
	};
	// JSX
	return (
		<RegistrationLayout>
			<LoginLayoutBody
				title="Login"
				description="<p>
							Welcome to <strong> CostAllocation Pro! </strong>Please Enter your
							Details.
						</p>"
				formData={FORMDATA.loginFields}
				buttonTitle={'Sign in'}
				action={loginAction}
				redirectUrl="/forgot-password"
				redirectText="Forgot password?"
				onSubmit={onSubmit}
				setReCaptchaValue={setReCaptchaValue}
				recaptchaRef={recaptchaRef}
				isLoading={isLoading}
			></LoginLayoutBody>
		</RegistrationLayout>
	);
};

export default Login;
