import React, { useEffect, useState } from 'react';
import { getApi } from 'redux/apis';
import styles from './index.module.scss';
import { toastText } from 'utils/utils';
import { useSearchParams } from 'react-router-dom';

const QuickbooksDisconnectCallback = () => {
	const [isLoading, setIsLoading] = useState(false);

	const [searchParams] = useSearchParams();

	useEffect(() => {
		const run = async () => {
			setIsLoading(true);

			try {
				localStorage.setItem('isLogin', 'true');
				const response = await getApi('/quickbooks/sso-authurl');
				localStorage.setItem('disconnect-companyId', searchParams.get('realmId') as string);
				window.open(response.data.data, '_self');
			} catch (err) {
				toastText('Something went wrong in login.', 'error');
			}
		};
		if(searchParams.get('realmId')) {
			run();
		}
	}, [searchParams]);

	return (
		<div className={styles['main-container']}>
			{isLoading && <img src="assets/gifs/loading-black.gif" />}
		</div>
	);
};

export default QuickbooksDisconnectCallback;
