import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkPermission } from 'utils/utils';
import CustomerSummaryReport from '../CustomerSummryReport';
import PayrollSummaryReport from '../PayrollSummaryReport';
import TimeSummaryReport from '../TimeSummaryReport';

// type Props = {};

const ReportsMain = () => {
	const [queryParams] = useSearchParams();
	const [selectedReport, setSelectedReport] = useState(
		queryParams.get('reportTab') || '1'
	);

	const navigate = useNavigate();

	const handleChangeReport = (e: any) => {
		setSelectedReport(e);
	};

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const isTimeSummaryPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Time Summary',
		permission: ['view'],
	});

	const isPayrollSummaryPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Payroll Summary',
			permission: ['view'],
		}
	);

	const isCustomerSummaryPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Customer Overview',
			permission: ['view'],
		}
	);

	const items = [];

	if (isTimeSummaryPermission) {
		items.push({
			key: '1',
			label: 'Time Summary Report',
			children: <TimeSummaryReport />,
		});
	}

	if (isPayrollSummaryPermission) {
		items.push({
			key: '2',
			label: 'Payroll Summary Report',
			children: <PayrollSummaryReport />,
		});
	}

	if (isCustomerSummaryPermission) {
		items.push({
			key: '3',
			label: 'Customer Summary Report',
			children: <CustomerSummaryReport />,
		});
	}

	useEffect(() => {
		if (selectedCompanyPermission && selectedCompanyPermission.length) {
			if (isTimeSummaryPermission) {
				setSelectedReport('1');
			} else if (isPayrollSummaryPermission) {
				setSelectedReport('2');
			} else if (isCustomerSummaryPermission) {
				setSelectedReport('3');
			} else {
				navigate('/unauthorized');
			}
		}
	}, [
		isTimeSummaryPermission,
		isPayrollSummaryPermission,
		isCustomerSummaryPermission,
		selectedCompanyPermission,
	]);

	return (
		<div>
			<Tabs
				defaultActiveKey="1"
				items={items}
				activeKey={selectedReport}
				key={selectedReport}
				tabBarStyle={{
					paddingLeft: '20px',
					fontSize: '18px',
				}}
				onChange={(e) => {
					handleChangeReport(e);
				}}
			/>
		</div>
	);
};

export default ReportsMain;
