import React, { useEffect, useState } from 'react';
import { getApi } from 'redux/apis';
import styles from './index.module.scss';
import { toastText } from 'utils/utils';
import { useNavigate } from 'react-router-dom';

const QuickbooksLaunchCallback = () => {
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		const run = async () => {
			setIsLoading(true);

			if (!localStorage.getItem('accessToken')) {
				try {
					localStorage.setItem('isLogin', 'true');
					const response = await getApi('/quickbooks/sso-authurl');
					window.open(response.data.data, '_self');
				} catch (err) {
					toastText('Something went wrong in login.', 'error');
				}
			} else {
				navigate('/');
			}

		};
		run();
	}, []);

	return (
		<div className={styles['main-container']}>
			{isLoading && <img src="assets/gifs/loading-black.gif" />}
		</div>
	);
};

export default QuickbooksLaunchCallback;
