import { createSlice } from '@reduxjs/toolkit';
import { getConfiguration } from 'redux/action/configurationAction';

const initialState = {
    isLoading: false,
    data: null,
    error: null,
};

const configurationSlice = createSlice({
    initialState,
    name: 'configuration',
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getConfiguration.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getConfiguration.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.isLoading = false;
        });
        builder.addCase(
            getConfiguration.rejected,
            (state, action: any) => {
                state.error = action.error;
                state.isLoading = false;
            }
        );
    },
});

export default configurationSlice;
