import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { classAction } from 'redux/action/classAction';
import { customerOptionsAction } from 'redux/action/customerAction';
import { AppDispatch } from 'redux/store';
import { useDebounce } from 'use-debounce';
import SearchAndFilter from './SearchAndFilter';
import TimeSummaryTable from './Table';
import styles from './index.module.scss';
import { getTimeSummaryReportAction } from 'redux/action/reportsAction';
import { hasText } from 'utils/utils';
import dayjs from 'dayjs';

const TimeSummaryReport = () => {
	const dispatch = useDispatch<AppDispatch>();
	const [queryParams, setQueryParams] = useSearchParams();
	const [selectedPayPeriod, setSelectedPayPeriod] = useState<string | null>(
		queryParams.get('payPeriod') || localStorage.getItem('payPeriod') || null
	);
	const navigate = useNavigate();
	const [searchValue, setSearchValue] = useState<string>('');
	const [debounceSearchValue] = useDebounce(searchValue, 1000);
	const [applySearch, setApplySearch] = useState<boolean>(false);
	const [filterValue, setFilterValue] = useState<any>({});
	const [yearValue, setYearValue] = useState<any>(dayjs());
	// const [isInViewPort, setIsInViewPort] = useState<boolean>(false);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	const handlePayPeriod = (payPeriodId: string) => {
		setApplySearch(true);
		setYearValue(null);
		setSelectedPayPeriod(payPeriodId);
		setQueryParams({ payPeriod: payPeriodId });
	};

	const performFilterHandler = (key: string, value: string) => {
		setFilterValue({ ...filterValue, [key]: value });
		setApplySearch(true);
	};

	const clearFilter = () => {
		setApplySearch(true);
		setSearchValue('');
		setFilterValue({});
		setSelectedPayPeriod(null);
		setYearValue(null);
	};

	const performSearchHandler = (value: string) => {
		setSearchValue(value);
		setApplySearch(true);
	};

	const handleYear = (value: any) => {
		setSelectedPayPeriod(null);
		setYearValue(value);
		setApplySearch(true);
	};

	const getTimeSummaryReportData = async () => {
		const query: any = {
			...filterValue,
			search: searchValue,
			payPeriodId: selectedPayPeriod,
		};

		if (yearValue) {
			query.year = yearValue['$y'];
		}

		if (!hasText(searchValue)) {
			delete query.search;
		}

		if (!hasText(selectedPayPeriod)) {
			delete query.payPeriodId;
		}

		if (!hasText(filterValue.customerId)) {
			delete query.customerId;
		}

		const resultAction = await dispatch(getTimeSummaryReportAction(query));
		if (getTimeSummaryReportAction.rejected.match(resultAction)) {
			navigate('/login');
			window.location.reload();
		}
	};

	useEffect(() => {
		dispatch(classAction());
		dispatch(customerOptionsAction());
		getTimeSummaryReportData();
	}, []);

	useEffect(() => {
		dispatch(classAction());
		dispatch(customerOptionsAction());
		setApplySearch(true);
		setSelectedPayPeriod(null);
	}, [selectedCompanyDetails]);

	useEffect(() => {
		if (applySearch) {
			getTimeSummaryReportData();
		}
	}, [
		selectedPayPeriod,
		debounceSearchValue,
		filterValue,
		yearValue,
		selectedCompanyDetails,
	]);

	useEffect(() => {
		if (selectedPayPeriod) {
			handlePayPeriod(selectedPayPeriod);
		}
	}, [selectedPayPeriod]);

	return (
		<div>
			<div className={styles['time-summary__header']}>
				<SearchAndFilter
					handlePayPeriod={handlePayPeriod}
					selectedPayPeriod={selectedPayPeriod}
					performFilterHandler={performFilterHandler}
					performSearchHandler={performSearchHandler}
					clearFilter={clearFilter}
					searchValue={searchValue}
					filterValue={filterValue}
					yearHandler={handleYear}
					yearValue={yearValue}
				/>
			</div>
			<div className={styles['time-summary__table']}>
				<TimeSummaryTable />
			</div>
		</div>
	);
};

export default TimeSummaryReport;
