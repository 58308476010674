import { Button, Form } from 'antd';
import { InputWithLabelAndSvg } from 'components/Global';
import { FC } from 'react';
import styles from './index.module.scss';
import { LoginLayoutBodyProps } from './types';
import { Link, useNavigate } from 'react-router-dom';
import { toastText } from 'utils/utils';
import { getApi } from 'redux/apis';
import ReCAPTCHA from 'react-google-recaptcha';

// Login layout body designing
const LoginLayoutBody: FC<LoginLayoutBodyProps> = (props) => {
	// Inits
	const {
		title,
		description,
		formData: loginFields,
		buttonTitle,
		// action,
		redirectText,
		redirectUrl,
		onSubmit,
		isLoading,
		recaptchaRef,
		setReCaptchaValue,
	} = props;

	const navigate = useNavigate();

	const intuitLoginHandler = async () => {
		try {
			localStorage.setItem('isLogin', 'true');
			const response = await getApi('/quickbooks/sso-authurl');
			window.open(response.data.data, '_self');
		} catch (err) {
			toastText('Something went wrong in login.', 'error');
		}
	};
	const reCaptchaChange = async (value: any) => {
		setReCaptchaValue(value);
	};

	// JSX
	return (
		<div className={styles['login-body']}>
			<Form
				className={styles['login-body__wrapper']}
				name="basic"
				onFinish={onSubmit}
			>
				<div className={styles['login-body__top']}>
					<h4 className={styles['login-body__top--title']}>{title}</h4>
					{description && (
						<div className={styles['login-body__top--description']}>
							<p dangerouslySetInnerHTML={{ __html: description }} />
						</div>
					)}
				</div>
				<div className={styles['login-body__center']}>
					{loginFields.map((singleUserInput, key) => {
						return (
							<InputWithLabelAndSvg
								key={key}
								singleUserInput={singleUserInput}
							/>
						);
					})}
				</div>
				{title === 'Login' && (
					<div>
						<ReCAPTCHA
							sitekey={'6LeMliIqAAAAAL1jaa0gVCjymWVoQsYowh9bsgsB'}
							ref={recaptchaRef}
							onChange={reCaptchaChange}
						/>
					</div>
				)}
				<div className={styles['intuit_button_container']}>
					{title === 'Login' && (
						<p>
							Don&apos;t have an account?{' '}
							<Link
								to={
									process.env.REACT_APP_ZOHO_SUBSCRIPTION ||
									'https://www.costallocationpro.com/'
								}
								target="_blank"
							>
								Sign up now
							</Link>
						</p>
					)}
					<p
						className={styles['login-body__forgot-password']}
						onClick={() => navigate(`${redirectUrl}`)}
					>
						{redirectText}
					</p>
				</div>

				<div className={styles['login-body__end']}>
					<Button
						type="primary"
						className={`${styles['login-body__end--button']} ${
							isLoading && 'pointer-event-none'
						}`}
						size="large"
						htmlType="submit"
						// disabled={isLoading}
					>
						{isLoading ? (
							<img
								src="assets/gifs/loading-black.gif"
								height={40}
								loading="lazy"
							/>
						) : (
							<>{buttonTitle}</>
						)}
					</Button>
				</div>
				{title === 'Login' && (
					<div className={styles['intuit_button_container']}>
						<button
							onClick={intuitLoginHandler}
							className={styles['intuit_button']}
							type="button"
						>
							Sign in with Intuit
						</button>
					</div>
				)}
			</Form>
		</div>
	);
};

export default LoginLayoutBody;
