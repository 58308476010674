import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { subScriptionStatus } from 'utils/constant';
import styles from './index.module.scss';
import { getApi } from 'redux/apis';
// import { toastText } from 'utils/utils';

const SubscriptionDetails = () => {
	const { companies } = useSelector((state: any) => state?.userProfile?.data);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	const [subscriptionsData, setSubscriptionsData] = useState<any>(null);

	// const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (companies && companies.length) {
			// const selectedCompanyId = localStorage.getItem('companyId');

			// if (selectedCompanyId) {
			// 	const selectedCompanyData = companies.find(
			// 		(e: any) => e.companyId === selectedCompanyId
			// 	);

			// 	if (
			// 		selectedCompanyData &&
			// 		selectedCompanyData.company &&
			// 		selectedCompanyData.company.Subscription &&
			// 		selectedCompanyData.company.Subscription.length
			// 	) {
			// 		setSubscriptionData(selectedCompanyData.company.Subscription[0]);
			// 	}
			// }
			getSubscriptions().catch(console.error);
		}

	}, [companies, selectedCompanyDetails]);

	const getSubscriptions = async () => {
		const res = await getApi('/subscription/logged-in', {
			companyId: localStorage.getItem('companyId')
		})
		setSubscriptionsData(res.data.data);
	}

	// const getHostedPaymentPage =  async() => {
	// 	try {
	// 		setIsLoading(true);
	// 		const res = await getApi('/zoho/hosted-page', {
	// 			companyId: subscriptionsData[0].companyId
	// 		})

	// 		if (res.data && res.data.data && res.data.data.hostedUrl) {
	// 			window.open(res.data.data.hostedUrl, '_blank')
	// 		}

	// 	} catch (error: any) {
	// 		toastText(error.message, 'error');			
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };

	return (
		<div className={styles['subscription']}>
			<div className={styles['subscription__main-header']} >
				<p className={styles['subscription__text']} >Active Plans: {subscriptionsData?.filter((e: any) => e.status === 'live' || e.status === 'trial').length}</p>
				<button
					className={`${styles['subscription__main-header--btn']} btn-black`}
					onClick={() => {
						window.open(`${process.env?.REACT_APP_CUSTOMER_PORTAL_LINK}`, '_blank')
					}}
				>
					Manage Subscription
				</button>
			</div>
			{
				subscriptionsData?.map((subscriptionData: any) => (
					<div style={{ marginBottom: '20px' }} key={subscriptionData.id}>
						<div className={styles['subscription__header']}>
							<div className={styles['subscription__header--heading']}>
								Plan Details
								{subscriptionData && subscriptionData?.status ? (
									<span
										style={{
											marginLeft: '10px',
											color: 'white',
											background:
												subScriptionStatus[
													subscriptionData?.status === 'live' || subscriptionData?.status === 'trial' ? 'live' : 'cancel'
												]?.color,
											padding: '2px 10px',
											borderRadius: '5px',
										}}
									>
										{
											subScriptionStatus[
												subscriptionData?.status === 'live' || subscriptionData?.status === 'trial' ? 'live' : 'cancel'
											]?.name
										}
									</span>
								) : null}
							</div>

							{/* <div className={styles['subscription__header--action']}>
							<Button
								className={styles['subscription__header--action-button']}
								onClick={() =>
									window.open(process.env.REACT_APP_ZOHO_SUBSCRIPTION, '_self')
								}
							>
								Manage Subscription
							</Button>
						</div> */}
						</div>

						{subscriptionData ? (
							<div className={styles['subscription__content']}>
								<Row gutter={24}>
									<Col md={6} lg={6} sm={6}>
										<div className={styles['subscription__content--box']}>
											<div className={styles['subscription__content--box-header']}>
												Current Plan: {subscriptionData?.zohoSubscriptionPlan?.plan_code}
											</div>
											<div className={styles['subscription__content--box-content']}>
												<ul>
													<li>Subscription Id: <b>{subscriptionData?.zohoSubscriptionId}</b></li>
													<li>{subscriptionData?.zohoSubscriptionPlan?.name}</li>
													<li>10 Users</li>
													<li>1 QuickBooks Company</li>
												</ul>
											</div>
										</div>
									</Col>

									<Col md={6} lg={6} sm={6}>
										<div className={styles['subscription__content--box']}>
											<div className={styles['subscription__content--box-header']}>
												Pricing
											</div>
											<div className={styles['subscription__content--box-content']}>
												<p
													className={
														styles['subscription__content--box-content-pricing']
													}
												>
													${subscriptionData?.zohoSubscriptionPlan?.price}
												</p>
												<p
													className={
														styles['subscription__content--box-content-description']
													}
												>
													/ Per Month
												</p>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						) : null}
					</div>
				))
			}
		</div>
	);
};

export default SubscriptionDetails;
