import React, { useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Row, Form, Upload, Button } from 'antd';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import './index.scss';

// Define allowed file extensions
const allowedFileExtensions = ['.csv', '.xls', '.xlsx'];

// Validation schema
const validationSchema = Yup.object().shape({
	file: Yup.mixed()
		.required('Please upload a file')
		.test('fileType', 'Unsupported file format', (value: any) => {
			if (value) {
				const fileExtension = value.name.split('.').pop()?.toLowerCase();
				return (
					fileExtension && allowedFileExtensions.includes(`.${fileExtension}`)
				);
			}
			return false;
		}),
});

interface UploadFileFormProps {
	handleUploadFileForm: (file: any) => void;
	handleNext: () => void;
	file: any;
}

function UploadFileForm({
	handleUploadFileForm,
	file,
	handleNext,
}: UploadFileFormProps) {
	const initialValues = {
		file: file ? file : null,
	};

	useEffect(() => {
		console.log(file);
	}, [initialValues]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: any, { setSubmitting }) => {
				setSubmitting(true);
				handleNext();
				setSubmitting(false);
			}}
		>
			{({
				values,
				errors,
				touched,
				setFieldValue,
				handleSubmit,
				isSubmitting,
			}) => (
				<Form onFinish={handleSubmit}>
					<Row
						justify="center"
						className="customRow"
					>
						<Col span={24}>
							<Form.Item
								validateStatus={
									touched.file && errors.file ? 'error' : undefined
								}
								help={
									touched.file && errors.file
										? (errors.file as string)
										: undefined
								}
							>
								<Upload.Dragger
									name="file"
									action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
									onChange={(info) => {
										console.log('status');

										// const { status } = info.file;
										console.log('status', info);

										// if (status === 'done') {
										handleUploadFileForm(values);
										// }
									}}
									beforeUpload={(file) => {
										setFieldValue('file', file);
										return false;
									}}
									multiple={false}
									accept=".csv,.xls,.xlsx"
									showUploadList={false}
								>
									<p className="ant-upload-drag-icon">
										<InboxOutlined />
									</p>
									<p className="ant-upload-text">
										Click or drag file to this area to upload
									</p>
									<p className="ant-upload-hint">
										Support for a single upload. Valid formats: .csv, .xls,
										.xlsx
									</p>
								</Upload.Dragger>
								{values?.file?.name && file && (
									<div
										style={{
											marginTop: 16,
											textAlign: 'center',
											backgroundColor: '#fafafa',
										}}
									>
										<span>{values?.file?.name}</span>
										<Button
											type="link"
											icon={<DeleteOutlined />}
											onClick={() => {
												setFieldValue('file', null);
											}}
											style={{ marginLeft: 8, color: '#e74031' }}
										/>
									</div>
								)}
							</Form.Item>
						</Col>
						<Col
							span={24}
							className="text-center"
						></Col>
					</Row>
					<Row
						style={{
							marginTop: '15px',
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						<button
							disabled={isSubmitting}
							className="btn-black customButton"
						>
							Next
						</button>
					</Row>
				</Form>
			)}
		</Formik>
	);
}

export default UploadFileForm;
