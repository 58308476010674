import { createSlice } from '@reduxjs/toolkit';
import {
	getCustomerSummaryReportAction,
	getPayrollSummaryReportAction,
	getTimeSummaryReportAction,
} from 'redux/action/reportsAction';
const initialState: any = {
	isTimeSummaryLoading: false,
	timeSummaryData: [],
	timeSummaryError: null,
	isCustomerSummaryLoading: false,
	customerSummaryData: [],
	customerSummaryError: null,
	isPayrollSummaryLoading: false,
	payRollSummaryData: [],
	payRollPayPeriodId: null,
	payrollSummaryError: null
};

const reportsSlice = createSlice({
	initialState,
	name: 'reports',
	reducers: {},
	extraReducers: (builder) => {
		//Time summary
		builder.addCase(getTimeSummaryReportAction.pending, (state) => {
			state.isTimeSummaryLoading = true;
			state.timeSummaryData = [];
		});
		builder.addCase(getTimeSummaryReportAction.fulfilled, (state, action) => {
			state.isTimeSummaryLoading = false;
			state.timeSummaryData = action.payload.data;
			state.timeSummaryError = null;
		});
		builder.addCase(
			getTimeSummaryReportAction.rejected,
			(state: any, action) => {
				state.isTimeSummaryLoading = false;
				state.timeSummaryData = [];
				state.timeSummaryError = action.payload;
			}
		);

		//Customer Expense
		builder.addCase(getCustomerSummaryReportAction.pending, (state) => {
			state.isCustomerSummaryLoading = true;
			state.customerSummaryData = [];
		});
		builder.addCase(
			getCustomerSummaryReportAction.fulfilled,
			(state, action) => {
				state.isCustomerSummaryLoading = false;
				state.customerSummaryData = action.payload.data;
				state.customerSummaryError = null;
			}
		);
		builder.addCase(
			getCustomerSummaryReportAction.rejected,
			(state: any, action) => {
				state.isCustomerSummaryLoading = false;
				state.customerSummaryData = [];
				state.customerSummaryError = action.payload;
			}
		);

		//Payroll Expense
		builder.addCase(getPayrollSummaryReportAction.pending, (state) => {
			state.isPayrollSummaryLoading = true;
			state.payRollSummaryData = [];
		});
		builder.addCase(
			getPayrollSummaryReportAction.fulfilled,
			(state, action) => {
				state.isPayrollSummaryLoading = false;
				state.payRollSummaryData = action.payload.data.content;
				state.payRollPayPeriodId = action.payload.data.currentPayPeriodId;
				state.payrollSummaryError = null;
			}
		);
		builder.addCase(
			getPayrollSummaryReportAction.rejected,
			(state: any, action) => {
				state.isPayrollSummaryLoading = false;
				state.payRollSummaryData = [];
				state.payrollSummaryError = action.payload;
			}
		);
	},
});

export default reportsSlice;
