import { EmployeeCostMain } from 'components/EmployeeCost';
import { Loader } from 'components/Global';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { getEmployeeCostAction } from 'redux/action/employeeCostAction';
// import { AppDispatch } from 'redux/store';
// import { checkPermission } from 'utils/utils';
import './index.css';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const EmployeeCosts = () => {
	// const dispatch = useDispatch<AppDispatch>();
	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);
	const { fistTimeFetchLoading } = useSelector(
		(state: any) => state?.employeeCosts
	);
	// const payPeriodId = useSelector(
	// 	(state: any) => state?.payPeriods?.createdPayPeriod?.id
	// );

	const [payPeriodId, setPayPeriodId] = useState<string | null>(null);

	const [isEmployeeCost, setIsEmployeeCost] = useState('1');

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showPreviewCostAllocation, setShowPreviewCostAllocation] = useState(true);
	const [isValueChanged, setIsValueChanged] = useState(false);

	const navigate = useNavigate();

	const { closingDate } = useSelector((state: any) => state?.closingDate);
	const payPeriods = useSelector((state: any) => state?.payPeriods?.optionData);

	// const initialFunction = () => {
	// 	console.log("Pay : ", payPeriodId);
	// 	dispatch(
	// 		getEmployeeCostAction({
	// 			payPeriodId,
	// 			page: 1,
	// 			limit: 10,
	// 		})
	// 	);
	// 	if (selectedCompanyPermission) {
	// 		const isViewEmployeeCostPermission = checkPermission(
	// 			selectedCompanyPermission,
	// 			{
	// 				permissionName: 'Employee Cost',
	// 				permission: ['view'],
	// 			}
	// 		);

	// 		if (!isViewEmployeeCostPermission) {
	// 			navigate('/unauthorized');
	// 		}
	// 	}
	// };

	useEffect(() => {
		// initialFunction();
	}, [selectedCompanyPermission, payPeriodId]);

	useEffect(() => {
		document.title = 'CostAllocation Pro - Employee Costs';
	}, []);

	useEffect(() => {

		if (payPeriods && payPeriodId && payPeriods.length && closingDate) {
			const payPeriodData = payPeriods.find((e: any) => e.id === payPeriodId);
			setShowPreviewCostAllocation(payPeriodData &&
				payPeriodData.isJournalPublished &&
				(dayjs(payPeriodData?.endDate) > dayjs(closingDate).endOf('day')));
		} else {
			setShowPreviewCostAllocation(false);
		}

	}, [payPeriodId, payPeriods, closingDate])

	return !fistTimeFetchLoading ? (
		<div className={styles['employee-container']}>
			<div className={styles['employee-container__header']}>
				<div className={styles['employee-container__header--title']} >
					Employee Costs
				</div>
				{
					isEmployeeCost == '1' && showPreviewCostAllocation && isValueChanged ? (
						<div className={styles['employee-container__header--actions']} >
							<button
								className={styles['employee-container__header--actions-preview']}
								onClick={() => {
									navigate(`/cost-allocations?payPeriod=${payPeriodId}`)
								}}
							>
								Preview Cost Allocation
							</button>
						</div>
					) : null
				}

			</div>
			<div className={styles['employee-container__table']}>
				<div className={styles['table-employee']}>
					<EmployeeCostMain
						onChangeKey={(value: any) => {
							setIsEmployeeCost(value);
						}}
						onChangePayPeriod={(id: string) => {
							setPayPeriodId(id);
						}}
						onChangeCostValue={(flag: boolean) => {
							setIsValueChanged(flag)
						}}
					/>
				</div>
			</div>
		</div>
	) : (
		<Loader />
	);
};

export default EmployeeCosts;
