/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useRef, useState } from 'react';
import DynamicTable from './Table';
import styles from './inedx.module.scss';
import { TimeSheetProps } from './types';

// Time sheet
const TimeSheet = (props: TimeSheetProps) => {
	const { onChangePayPeriod, changeTimeSheetTab } = props;

	const handleChangeTimeSheetTab = (flag: any) => {
		changeTimeSheetTab && changeTimeSheetTab(flag);
	}

	// JSX
	return (
		<div className={styles['time-sheet']}>
			<div className={styles['time-sheet__wrapper']}>
				<DynamicTable
					onChangePayPeriod={onChangePayPeriod}
					changeTimeSheetTab={handleChangeTimeSheetTab}
				/>
			</div>
		</div>
	);
};

export default TimeSheet;
