import { Col, Modal, Row, Button } from 'antd';
import styles from './index.module.scss';
import { WarningOutlined } from '@ant-design/icons';
import { NumericFormat } from 'react-number-format';

interface Props {
	isOpen: boolean;
	data: any;
	onCancel: () => void;
	onConfigure: () => void;
}

export const CostAllocationDifferenceModal = (props: Props) => {
	const { isOpen, data, onCancel, onConfigure } = props;

	const handleConfigure = () => {
		onConfigure && onConfigure()
	}

	return (
		<Modal
			open={isOpen}
			closable={false}
			footer={null}
			className={styles["time-sheet-validate-modal profile__popup"]}
			width={800}
		>
			<p style={{ fontWeight: 600, fontSize: '28px', marginBottom: '10px', borderBottom: '1px solid #ccc', paddingBottom: '10px' }} >
				<WarningOutlined />&nbsp;
				Warning
			</p>
			<div style={{ marginBottom: '10px' }} >
				The total Employee Cost (
				<NumericFormat
					value={data.totalEmployeeCostWithoutIndirectRate}
					thousandSeparator=","
					decimalScale={2}
					fixedDecimalScale={true}
					displayType="text"
					prefix="$"
				/>
				) does not match the total Cost Allocation (
				<NumericFormat
					value={data.totalAllocation}
					thousandSeparator=","
					decimalScale={2}
					fixedDecimalScale={true}
					displayType="text"
					prefix="$"
				/>
				). There may be discrepancies that require further review and adjustment.
				{/* Total Employee Cost With out Indirect Expense Rate: <NumericFormat
					value={data.totalEmployeeCostWithoutIndirectRate}
					thousandSeparator=","
					decimalScale={2}
					fixedDecimalScale={true}
					displayType="text"
					prefix="$"
				/> */}
			</div>
			{/* <div style={{ marginBottom: '10px' }} >
				Total Employee Cost With Indirect Expense Rate: <NumericFormat
					value={data.totalEmployeeCost}
					thousandSeparator=","
					decimalScale={2}
					fixedDecimalScale={true}
					displayType="text"
					prefix="$"
				/>
			</div>
			<div style={{ marginBottom: '10px' }} >
				Total Cost Allocation: <NumericFormat
					value={data.totalAllocation}
					thousandSeparator=","
					decimalScale={2}
					fixedDecimalScale={true}
					displayType="text"
					prefix="$"
				/>
			</div> */}
			{
				data.employees && data.employees.length ? (
					<>
						<div style={{ marginBottom: '10px' }} >
							List of employees which are not included in cost allocation
						</div>
						<div className={styles['warning-container']} >
							{
								data.employees.map((e: any, index: number) => (
									<p style={{ fontSize: '14px' }} key={e} >{index + 1}. {e}</p>
								))
							}
						</div>
					</>
				) : null
			}

			<Row style={{ marginTop: '15px' }}>
				<Col xs={24} md={8} lg={8} sm={8}>
					<div style={{ display: 'flex', gap: '10px' }} >
						{
							data.employees && data.employees.length ? (
								<Button className={styles['cancel']} onClick={handleConfigure}>
									Configure
								</Button>
							) : null
						}
						<Button className={styles['save']} onClick={onCancel}>
							Proceed
						</Button>
					</div>
				</Col>
			</Row>
		</Modal>
	);
};