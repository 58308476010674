import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApi, putApi } from 'redux/apis';

export const updateConfiguration = createAsyncThunk(
	'configuration/update',
	async (configurationData: any, { rejectWithValue }) => {
		try {
			const response = await putApi('/companies/configuration', {
				companyId: localStorage.getItem('companyId'),
				...configurationData,
			});
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const getConfiguration = createAsyncThunk(
	'configuration/get',
	async (query: any, { rejectWithValue }) => {
		try {
			const response = await getApi('/companies/configuration', {
				companyId: localStorage.getItem('companyId'),
				...query,
			});
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error?.response?.data);
		}
	}
);
