import { SearchOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	Input,
	Modal,
	Row,
	Select,
	Space,
	TreeSelect,
} from 'antd';
import ClearFilter from 'components/Global/ClearFilter';
import PayPeriodFilter from 'components/Global/PayPeriodFilter';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { postApi, putApiParams } from 'redux/apis';
import { createModifiedArray, invalidText, toastText } from 'utils/utils';
import BatchEditModal from '../BatchEditModal';
import styles from './index.module.scss';
import './index.scss';
import { SearchAndFilterProps } from './types';
import { CloseSvg } from 'utils/svgs';
import { useSearchParams } from 'react-router-dom';
import BatchDeleteModal from '../BatchDeleteModal';

// For search filter and paginate
const SearchAndFilter: FC<SearchAndFilterProps> = (props) => {
	const {
		searchValue,
		performFilterHandler,
		filterValue,
		onChangeSearchValue,
		onChangePayPeriod,
		selectedPayPeriod,
		clearFilter,
		isBatchEdit,
		selectedRowKeys,
		isAllSelected,
		initialCall,
		changePayPeriodBatchEdit,
	} = props;

	const { optionData: customer } = useSelector((state: any) => state.customer);
	// const { data: customer } = useSelector((state: any) => state.customer);
	const { optionData: classList } = useSelector((state: any) => state.class);
	const { data: employees } = useSelector((state: any) => state.employees);
	const { data: configurations } = useSelector(
		(state: any) => state?.configuration
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [queryParams, setQueryParams] = useSearchParams();
	// configurationsData?.isCustomerRequiredForJournal;
	// const { data: configurationsData } = useSelector(
	// 	(state: any) => state?.configuration
	// );

	const [filteredClassList, setFilteredClassList] = useState(classList);
	const [isBatchModalOpen, setIsBatchModalOpen] = useState(false);
	const [isBatchDeleteModalOpen, setIsBatchDeleteModalOpen] = useState(false);

	const [isClassEdit, setIsClassEdit] = useState(false);
	const [isCustomerEdit, setIsCustomerEdit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [batchValues, setBatchValues] = useState({
		classId: null,
		className: null,
		customerId: null,
		customerName: null,
	});
	const [error, setError] = useState(false);
	const [deleteOption, setDeleteOption] = useState(false);

	const handleChangeSearchValue = (value: any) => {
		onChangeSearchValue && onChangeSearchValue(value);
	};

	const handleCancelModal = () => {
		setIsClassEdit(false);
		setIsCustomerEdit(false);
		setDeleteOption(false);
		setError(false);
		setBatchValues({
			classId: null,
			className: null,
			customerId: null,
			customerName: null,
		});
		setIsBatchDeleteModalOpen(false);
		setIsBatchModalOpen(false);
	};

	const checkValidation = () => {
		let isValid = true;
		if (
			(configurations?.isClassRequiredForJournal &&
				invalidText(batchValues.classId) &&
				isClassEdit) ||
			(configurations?.isCustomerRequiredForJournal &&
				invalidText(batchValues.customerId) &&
				isCustomerEdit)
		) {
			setError(true);
			setIsBatchModalOpen(false);
			isValid = false;
		}
		return isValid;
	};

	const batchHandler = (name: string, value: string) => {
		// checkChangeValidation(name, value);
		setBatchValues((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const batchDeleteHandler = async () => {
		// checkChangeValidation(name, value);
		let requestBody;
		if (deleteOption) {
			requestBody = {
				payPeriodId: selectedPayPeriod,
				companyId: localStorage.getItem('companyId'),
			};
		} else {
			requestBody = {
				timeActivityIds: selectedRowKeys,
				companyId: localStorage.getItem('companyId'),
			};
		}

		try {
			const response: any = await postApi(
				'/time-activities/bulk-delete',
				requestBody
			);

			if (response.data.success) {
				setIsBatchDeleteModalOpen(false);
				toastText(
					`Time Activities Deleted Successfully. (Count:${response.data.deletedCount})`,
					'success'
				);
				initialCall();
			} else {
				setIsBatchDeleteModalOpen(false);
				toastText('Something went wrong in deleting activities', 'error');
			}
		} catch (err: any) {
			let message = 'Something went wrong in deleting activities';
			if (err?.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		}
	};

	const closeWarningModal = () => {
		setIsClassEdit(false);
		setIsCustomerEdit(false);
		setBatchValues({
			classId: null,
			className: null,
			customerId: null,
			customerName: null,
		});
		setError(false);
	};

	const batchEditHandler = async () => {
		if (checkValidation()) {
			setIsLoading(true);
			try {
				const data: any = batchValues;
				if (isAllSelected) {
					data['isSelectedAll'] = isAllSelected;
				}
				if (selectedRowKeys.length > 0) {
					data['timeActivityIds'] = selectedRowKeys;
				}
				// if (data['classId'] === null) {
				// 	delete data['classId'];
				// 	delete data['className'];
				// }
				// if (data['customerId'] === null) {
				// 	delete data['customerId'];
				// 	delete data['customerName'];
				// }
				if (data['classId'] === '') {
					data['classId'] = null;
					data['className'] = null;
				}
				if (data['customerId'] === '') {
					data['customerId'] = null;
					data['customerName'] = null;
				}
				if (isClassEdit) {
					delete data['customerId'];
					delete data['customerName'];
				}
				if (isCustomerEdit) {
					delete data['classId'];
					delete data['className'];
				}

				await putApiParams('/time-activities/batch', data, {
					payPeriodId: selectedPayPeriod,
					companyId: localStorage.getItem('companyId'),
					...filterValue,
				});
				toastText('All activities updated successfully.', 'success');
				handleCancelModal();
				initialCall();
				// await dispatch(
				// 	getTimeLogs({
				// 		page: 1,
				// 		limit: 20,
				// 		payPeriodId: selectedPayPeriod,
				// 	})
				// );
			} catch (err) {
				toastText('Something went wrong in time activity update.', 'error');
			} finally {
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		if (
			configurations &&
			configurations.settings &&
			classList &&
			classList.length
		) {
			const _classList = createModifiedArray(
				classList,
				configurations.settings[0].fields['f1'].value
			);
			setFilteredClassList(_classList);
		}
	}, [configurations, classList]);

	const showConfigurationHandler = () => {
		setIsClassEdit(false);
		setIsCustomerEdit(false);
		setError(false);
		setQueryParams({
			payPeriodId: selectedPayPeriod as string,
			openDrawer: 'true',
		});
		localStorage.setItem('settings', 'Configurations');
		localStorage.setItem('payPeriod', selectedPayPeriod as string);
	};

	// JSX
	return (
		<div className={styles['search-filter']}>
			<div className={styles['search-filter__wrapper']}>
				<Row className={styles['custom-row']}>
					<Col className={styles['custom-col']}>
						<Space>
							<Input
								className={styles['search-filter__search']}
								placeholder="Search here..."
								suffix={<SearchOutlined />}
								onChange={(e) => {
									handleChangeSearchValue(e.target.value);
								}}
								value={searchValue}
								size="large"
							/>
							<Select
								className={styles['search-filter__filter']}
								style={{ width: 200 }}
								onChange={(value) => performFilterHandler('employeeId', value)}
								size="large"
								placeholder="Employee"
								value={filterValue?.employeeId}
								showSearch
								filterOption={(input, option: any) =>
									(option?.children as string)
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
							>
								<Select.Option value="">Select Employee</Select.Option>
								{employees?.map((employee: any, index: number) => {
									return (
										<Select.Option
											value={employee?.id}
											key={index}
										>
											{employee?.fullName}
										</Select.Option>
									);
								})}
							</Select>
							<TreeSelect
								className={styles['search-filter__filter']}
								showSearch
								style={{ width: '100%' }}
								value={filterValue?.customerId}
								dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
								placeholder="Customer"
								allowClear={false}
								treeDefaultExpandAll
								onChange={(value) => {
									performFilterHandler('customerId', value);
								}}
								size="large"
								treeData={customer}
								filterTreeNode={(inputValue, treeNode) => {
									// Customize the filtering logic here
									return treeNode.props.title
										.toLowerCase()
										.includes(inputValue.toLowerCase());
								}}
							/>
							<TreeSelect
								showSearch
								className={styles['search-filter__filter']}
								style={{ width: 200 }}
								onChange={(value) => {
									performFilterHandler('classId', value);
								}}
								value={filterValue?.classId}
								placeholder="Class"
								allowClear={false}
								treeDefaultExpandAll
								size="large"
								treeData={filteredClassList}
								filterTreeNode={(inputValue, treeNode) => {
									// Customize the filtering logic here
									return treeNode.props.title
										.toLowerCase()
										.includes(inputValue.toLowerCase());
								}}
							/>
							<PayPeriodFilter
								payPeriodId={selectedPayPeriod}
								onChangePayPeriodId={(value) => {
									onChangePayPeriod(value);
									changePayPeriodBatchEdit();
								}}
							/>
							<ClearFilter clearFilter={clearFilter} />
						</Space>
					</Col>
					<Col className={styles['custom-col']}>
						{isBatchEdit && (
							<>
								<div className={styles['button-container']}>
									<Button
										className={styles['batch-edit']}
										onClick={() => {
											setIsBatchModalOpen(true);
											setIsClassEdit(true);
										}}
									>
										Batch Edit
									</Button>
									<Button
										className={`${styles['batch-edit']} ${styles['margin-top']}`}
										onClick={() => {
											setIsBatchDeleteModalOpen(true);
											setDeleteOption(false);
											setIsClassEdit(true);
										}}
									>
										Batch Delete
									</Button>
								</div>
								{/* <Button
									className={styles['batch-edit']}
									onClick={() => {
										setIsBatchModalOpen(true);
										setIsCustomerEdit(true);
									}}
								>
									Edit Customer
								</Button> */}
							</>
						)}
					</Col>
				</Row>
			</div>
			<BatchEditModal
				isModalOpen={isBatchModalOpen}
				handleCancel={handleCancelModal}
				batchValues={batchValues}
				classList={filteredClassList}
				customerList={customer}
				batchHandler={batchHandler}
				batchEditHandler={batchEditHandler}
				error={error}
				isClassEdit={isClassEdit}
				isCustomerEdit={isCustomerEdit}
				isLoading={isLoading}
				setIsClassEdit={setIsClassEdit}
				setIsCustomerEdit={setIsCustomerEdit}
			/>

			<BatchDeleteModal
				isModalOpen={isBatchDeleteModalOpen}
				handleCancel={handleCancelModal}
				deleteOptionValue={deleteOption}
				handelChange={setDeleteOption}
				batchDeleteHandler={batchDeleteHandler}
				currentPayPeriod={selectedPayPeriod}
				isLoading={isLoading}
				error={error}
			/>

			<Modal
				open={error}
				onCancel={closeWarningModal}
				closable={false}
				footer={null}
				width={600}
				className="time-sheet-modal profile__popup"
			>
				<Row className={styles['time-sheet-modal__header']}>
					<Col span={22}>
						<div className="userDetailsTitle">
							<b>
								{`As per configuration ${
									isClassEdit ? 'Class' : 'Customer'
								} is mandatory.Please
								update the configuration.`}
							</b>
						</div>
					</Col>
					<div
						className={styles['time-sheet-modal__header-delete']}
						onClick={closeWarningModal}
						style={{
							display: 'flex',
							justifyContent: 'center',
							marginTop: '10px',
						}}
					>
						<CloseSvg />
					</div>
				</Row>
				<div className="time_sheet_modal_footer">
					<Row
						justify="start"
						className={styles['time-sheet-modal__body__buttons']}
						// gutter={16}
					>
						<Col span={8}>
							<Button
								className={`${styles['time-sheet-modal__body__send-email']} ${
									isLoading && 'pointer-event-none'
								}`}
								block={true}
								// onClick={batchEditHandler}
							>
								{isLoading ? (
									<img
										src="/assets/gifs/loading-black.gif"
										height={40}
									/>
								) : (
									<div
										className={
											styles['time-sheet-modal__body__send-email-inside']
										}
										onClick={() => {
											showConfigurationHandler();
										}}
									>
										<p>Configuration</p>
									</div>
								)}
							</Button>
						</Col>
						<Col span={8}>
							<Button
								className={`${styles['time-sheet-modal__body__send-email']}`}
								block={true}
								onClick={closeWarningModal}
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</div>
			</Modal>
		</div>
	);
};

export default SearchAndFilter;
