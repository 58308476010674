import React from 'react';
import { Col, Input, Row, Select, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

interface Props {
	performSearchHandler: (value: any) => void;
	searchValue: string;
	status: string | null;
	onChangeStatus: (value: string) => void;
	onClearFilter: (value: string) => void;
}

const SearchAndFilter = (props: Props) => {
	const { performSearchHandler, searchValue, onChangeStatus } = props;

	return (
		<div className={styles['search-filter']}>
			<div className={styles['search-filter__wrapper']}>
				<Row>
					<Col>
						<Space>
							<Input
								className={styles['search-filter__search']}
								placeholder="Search Rule"
								suffix={<SearchOutlined />}
								onChange={(e) => performSearchHandler(e.target.value)}
								value={searchValue}
								size="large"
							/>
							<Select
								defaultValue="All"
								style={{ width: 200 }}
								onChange={onChangeStatus}
								options={[
									{ label: 'All', value: '' },
									{ label: 'Active', value: 'Active' },
									{ label: 'Inactive', value: 'inActive' },
								]}
								size="large"
							/>
						</Space>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default SearchAndFilter;
