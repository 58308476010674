import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { classOptionsAction } from 'redux/action/classAction';
import { customerOptionsAction } from 'redux/action/customerAction';
import { getEmployeeAction } from 'redux/action/employeeAction';
import { getEmployeeCostColumnAction } from 'redux/action/employeeCostColumnSlice';
import { getPayrollSummaryReportAction } from 'redux/action/reportsAction';
import { AppDispatch } from 'redux/store';
import { useDebounce } from 'use-debounce';
import { hasText } from 'utils/utils';
import SearchAndFilter from './SearchAndFilter';
import PayrollSummaryTable from './Table';
import styles from './index.module.scss';
import { getConfiguration } from 'redux/action/configurationAction';

// type Props = {};

const PayrollSummaryReport = () => {
	const dispatch = useDispatch<AppDispatch>();
	const [queryParams, setQueryParams] = useSearchParams();
	const [selectedPayPeriod, setSelectedPayPeriod] = useState<string | null>(
		queryParams.get('payPeriod') || localStorage.getItem('payPeriod') || null
	);

	const [searchValue, setSearchValue] = useState<string>('');
	const [debounceSearchValue] = useDebounce(searchValue, 1000);
	const [applySearch, setApplySearch] = useState<boolean>(false);
	const [filterValue, setFilterValue] = useState<any>({});

	const { payRollPayPeriodId } = useSelector((state: any) => state.reports);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	// const [pageNo, setPageNo] = useState(1);
	// const [isInViewPort, setIsInViewPort] = useState<boolean>(false);
	useEffect(() => {
		dispatch(classOptionsAction());
		dispatch(customerOptionsAction());
		dispatch(getEmployeeAction());
		getPayrollSummaryReportData();
	}, []);

	useEffect(() => {
		if (selectedPayPeriod || payRollPayPeriodId) {
			dispatch(
				getEmployeeCostColumnAction({
					payPeriodId: selectedPayPeriod || payRollPayPeriodId,
				})
			);
			dispatch(
				getConfiguration({
					payPeriodId: selectedPayPeriod,
				})
			);
		}
	}, [selectedPayPeriod, payRollPayPeriodId]);

	useEffect(() => {
		if (selectedCompanyDetails) {
			dispatch(classOptionsAction());
			dispatch(customerOptionsAction());
			dispatch(getEmployeeAction());
			setSelectedPayPeriod(null);
			setApplySearch(true);
		}
	}, [selectedCompanyDetails]);

	useEffect(() => {
		if (applySearch) {
			getPayrollSummaryReportData();
		}
	}, [
		selectedPayPeriod,
		debounceSearchValue,
		filterValue,
		selectedCompanyDetails,
	]);

	useEffect(() => {
		if (payRollPayPeriodId) {
			setSelectedPayPeriod(payRollPayPeriodId);
		}
	}, [payRollPayPeriodId]);

	const getPayrollSummaryReportData = async () => {
		const query: any = {
			search: searchValue,
			payPeriodId: selectedPayPeriod,
			...filterValue,
		};

		if (!hasText(query.employeeId)) {
			delete query.employeeId;
		}

		if (!hasText(query.customerId)) {
			delete query.customerId;
		}

		if (!hasText(query.classId)) {
			delete query.classId;
		}

		if (!hasText(searchValue)) {
			delete query.search;
		}

		await dispatch(getPayrollSummaryReportAction(query));
	};

	const handlePayPeriod = (payPeriodId: string) => {
		setApplySearch(true);
		setSelectedPayPeriod(payPeriodId);
		setQueryParams({ payPeriod: payPeriodId });
	};

	const performFilterHandler = (key: string, value: string) => {
		setFilterValue({ ...filterValue, [key]: value });
		setApplySearch(true);
	};

	const clearFilter = () => {
		setApplySearch(true);
		setSearchValue('');
		setFilterValue({});
	};

	const performSearchHandler = (value: string) => {
		setSearchValue(value);
		setApplySearch(true);
	};

	return (
		<div>
			<div className={styles['payroll-summary__header']}>
				<SearchAndFilter
					handlePayPeriod={handlePayPeriod}
					selectedPayPeriod={selectedPayPeriod}
					performFilterHandler={performFilterHandler}
					performSearchHandler={performSearchHandler}
					clearFilter={clearFilter}
					searchValue={searchValue}
					filterValue={filterValue}
				/>
			</div>
			<div className={styles['payroll-summary__table']}>
				<PayrollSummaryTable />
			</div>
		</div>
	);
};

export default PayrollSummaryReport;
