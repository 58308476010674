import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isExpanded: false,
};

const layoutExpandSlice = createSlice({
    initialState,
    name: 'LayoutExpand',
    reducers: {
        updateLayOut: (state, action) => {
            state.isExpanded = action.payload.isExpanded;
        },
    }
});

export default layoutExpandSlice;
export const { updateLayOut } = layoutExpandSlice.actions;