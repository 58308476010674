import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, Select, Space } from 'antd';
import styles from './index.module.scss';
import './index.scss';
import ClearFilter from 'components/Global/ClearFilter';
import { SearchAndFilterProps } from './types';
import { EJournalStatus } from 'constants/Data';
import { DatePicker } from 'antd';

const SearchAndFilter = (props: SearchAndFilterProps) => {
	const {
		searchValue,
		performSearchHandler,
		filterValue,
		performFilterHandler,
		clearFilter,
		handleYear,
		year,
	} = props;

	const handleChangeSearchValue = (value: any) => {
		performSearchHandler && performSearchHandler(value);
	};

	return (
		<div className={styles['search-filter']}>
			<div className={styles['search-filter__wrapper']}>
				<Row justify="space-between">
					<Col>
						<Space>
							<Input
								className={styles['search-filter__search']}
								placeholder="Search here..."
								suffix={<SearchOutlined />}
								onChange={(e) => {
									handleChangeSearchValue(e.target.value);
								}}
								value={searchValue}
								size="large"
							/>
							<Select
								className={styles['search-filter__filter']}
								style={{ width: 200 }}
								size="large"
								placeholder="Status"
								showSearch
								value={filterValue?.status}
								filterOption={(input, option: any) =>
									(option?.children as string)
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
								onChange={(value) => performFilterHandler('status', value)}
							>
								<Select.Option value="">Select Status</Select.Option>
								<Select.Option value={EJournalStatus.PUBLISHED}>
									Published
								</Select.Option>
								<Select.Option value={EJournalStatus.DRAFT}>
									Draft
								</Select.Option>
								<Select.Option value={EJournalStatus.DELETED}>
									Deleted
								</Select.Option>
							</Select>
							{/* <PayPeriodFilter
								payPeriodId={selectedPayPeriod}
								onChangePayPeriodId={(value) => {
									onChangePayPeriod(value);
								}}
							/> */}
							<DatePicker
								size="large"
								picker="year"
								onChange={handleYear}
								value={year}
								clearIcon={false}
							/>
							<ClearFilter clearFilter={clearFilter} />
						</Space>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default SearchAndFilter;
