import { Button, Col, Form, Modal, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { CloseSvg } from 'utils/svgs';
import style from './index.module.scss';
import './index.scss';

interface Props {
	isModalOpen: boolean;
	handleOk: (value: string) => void;
	handleCancel: () => void;
}

const GetSupportModal = (props: Props) => {
	const { isModalOpen, handleCancel, handleOk } = props;

	const [form] = Form.useForm();

	return (
		<Modal
			open={isModalOpen}
			mask={true}
			footer={false}
			closable={false}
			className="get__support-modal"
		>
			<Form
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				autoComplete="off"
				form={form}
				onFinish={handleOk}
			>
				<Row className={style['get__support-modal-header']}>
					<div className={style['get__support-modal-title']}>
						<b> Contact US</b>
					</div>
					<div
						className={style['get__support-modal-close']}
						onClick={handleCancel}
					>
						<CloseSvg />
					</div>
				</Row>
				<hr />
				<div className={style['get__support-modal-body']}>
					<Row>
						<Col xs={24} md={8} lg={8} sm={8}>
							<p>Message *</p>
						</Col>
						<Col xs={24} md={16} lg={16} sm={16}>
							<Form.Item
								className="formItem"
								wrapperCol={{ span: 24 }}
								name="message"
								required={true}
								rules={[
									{
										required: true,
										message: 'Please enter message',
										validateTrigger: 'onChange',
									},
								]}
							>
								<TextArea
									rows={4}
									style={{
										width: '100%',
									}}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
				<hr />
				<Row
					justify="start"
					className={style['get__support-modal-footer']}
					gutter={16}
				>
					<Col xs={12} md={7} lg={7} sm={8}>
						<Button
							htmlType="submit"
							className={style['get__support-modal-footer-save']}
						>
							Send
						</Button>
					</Col>
					<Col xs={12} md={7} lg={7} sm={8}>
						<Button
							block={true}
							onClick={handleCancel}
							className={style['get__support-modal-footer-cancel']}
						>
							Cancel
						</Button>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default GetSupportModal;
