import { Modal } from 'antd';
import { MessageModalProps } from './types';
import styles from './index.module.scss';
import './index.scss';

const MessageModal = (props: MessageModalProps) => {
	const { isModalOpen, handleCancel, message } = props;

	return (
		<Modal
			open={isModalOpen}
			onCancel={handleCancel}
			className="custom-modal"
			closable={false}
			footer={[
				<div key={'wrapper'} className={styles['modal__footer']}>
					<button
						key="cancel"
						onClick={handleCancel}
						className={styles['modal__footer-button']}
					>
						OK
					</button>
				</div>,
			]}
		>
			<div className={styles['modal__message']}>{message}</div>
		</Modal>
	);
};

export default MessageModal;
