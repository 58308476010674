import { ClearFilterSvg } from 'utils/svgs';
import { ClearFilterProps } from './types';
import styles from './index.module.scss';

const ClearFilter = (props: ClearFilterProps) => {
	const { clearFilter } = props;
	return (
		<div onClick={clearFilter} className={styles['clear-filter-button']}>
			<ClearFilterSvg />
		</div>
	);
};

export default ClearFilter;
