import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, Select, Space, TreeSelect } from 'antd';
import ClearFilter from 'components/Global/ClearFilter';
import ExportData from 'components/Global/ExportData';
import PayPeriodFilter from 'components/Global/PayPeriodFilter';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { createModifiedArray } from 'utils/utils';

interface Props {
	handlePayPeriod: (payPeriodId: string) => void;
	selectedPayPeriod: string | null;
	performFilterHandler: (key: string, value: string) => void;
	performSearchHandler: (value: string) => void;
	searchValue: string;
	filterValue: any;
	clearFilter: () => void;
}

const SearchAndFilter = (props: Props) => {
	const {
		selectedPayPeriod,
		handlePayPeriod,
		clearFilter,
		performFilterHandler,
		performSearchHandler,
		filterValue,
		searchValue,
	} = props;

	const [filteredClassList, setFilteredClassList] = useState([]);

	const { data: configurations } = useSelector(
		(state: any) => state?.configuration
	);

	const { payRollSummaryData } = useSelector((state: any) => state.reports);
	const { optionData: customer } = useSelector((state: any) => state.customer);
	const { optionData: classList } = useSelector((state: any) => state.class);
	const { data: employees } = useSelector((state: any) => state.employees);
 
	useEffect(() => {
		if (
			configurations &&
			configurations.settings &&
			classList &&
			classList.length
		) { 
			const _classList: any = createModifiedArray(
				classList,
				configurations.settings[0].fields['f1'].value
			);
			setFilteredClassList(_classList);
		}
	}, [configurations, classList, selectedPayPeriod]);

	return (
		<div className={styles['search-filter']}>
			<div className={`${styles['search-filter__wrapper']}`}>
				<Row justify="space-between">
					<Col>
						<Space>
							<Input
								className={styles['search-filter__search']}
								placeholder="Search here..."
								suffix={<SearchOutlined />}
								onChange={(e) => {
									performSearchHandler(e.target.value);
								}}
								value={searchValue}
								size="large"
							/>

							<Select
								className={styles['search-filter__filter']}
								style={{ width: 200 }}
								onChange={(value) => performFilterHandler('employeeId', value)}
								size="large"
								placeholder="Employees"
								value={filterValue?.employeeId}
								showSearch
								filterOption={(input, option: any) =>
									(option?.children as string)
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
							>
								<Select.Option value="">Select Employee</Select.Option>
								{employees?.map((employee: any, index: number) => {
									return (
										<Select.Option value={employee?.id} key={index}>
											{employee?.fullName}
										</Select.Option>
									);
								})}
							</Select>

							<TreeSelect
								className={styles['search-filter__filter']}
								dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
								size="large"
								placeholder="Customer"
								value={filterValue?.customerId}
								showSearch
								onChange={(value) => performFilterHandler('customerId', value)}
								treeData={customer}
								treeDefaultExpandAll
								filterTreeNode={(inputValue, treeNode) => {
									// Customize the filtering logic here
									return treeNode.props.title
										.toLowerCase()
										.includes(inputValue.toLowerCase());
								}}
							/>
							<TreeSelect
								showSearch
								className={styles['search-filter__filter']}
								style={{ width: 200 }}
								onChange={(value) => {
									performFilterHandler('classId', value);
								}}
								value={filterValue?.classId}
								placeholder="Class"
								allowClear={false}
								treeDefaultExpandAll
								size="large"
								treeData={filteredClassList}
								filterTreeNode={(inputValue, treeNode) => {
									// Customize the filtering logic here
									return treeNode.props.title
										.toLowerCase()
										.includes(inputValue.toLowerCase());
								}}
							/>

							<PayPeriodFilter
								payPeriodId={selectedPayPeriod}
								onChangePayPeriodId={(value) => {
									handlePayPeriod(value);
								}}
							/>
							<ClearFilter clearFilter={clearFilter} />
						</Space>
					</Col>
					{payRollSummaryData && payRollSummaryData.length > 0 && (
						<Col>
							<Space className="search-filter-export">
								<ExportData
									moduleName="payrollSummaryReport"
									params={{
										payPeriodId: selectedPayPeriod,
										filterValue,
										searchValue,
									}}
								/>
							</Space>
						</Col>
					)}
				</Row>
			</div>
		</div>
	);
};

export default SearchAndFilter;
