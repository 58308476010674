import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { getApi } from 'redux/apis';
import { formatNumberWithCommasV2, toastText } from 'utils/utils';
import GraphModal from '../Modal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const EmployeeHours = (props: any) => {
	const { year } = props;
	const navigate = useNavigate();

	const [chartData, setChartData] = useState<any>({
		labels: [],
		datasets: [
			{
				label: 'Dataset 1',
				data: [],
				borderColor: 'none',
				backgroundColor: '#9CB891',
			},
		],
	});

	const [isModalOpen, setIsModalOpen] = useState(false);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleOpen = () => {
		setIsModalOpen(true);
	};

	useEffect(() => {
		fetchData();
	}, [year, selectedCompanyDetails]);

	const fetchData = async () => {
		try {
			let startYear;
			if (year && year?.split('-').length > 0) {
				startYear = year.split('-')[0]?.split(' ')[1];
			}
			const response = await getApi('/dashboard/hours-by-employee', {
				companyId: localStorage.getItem('companyId'),
				year: startYear,
			});

			const updatedChartData = {
				labels: response?.data?.data?.labels,
				datasets: [
					{
						label: 'Yearly Expense',
						data: response?.data?.data?.data,
						borderColor: 'none',
						backgroundColor: '#9CB891',
					},
				],
			};
			setChartData(updatedChartData);
		} catch (err: any) {
			if (err?.response?.data?.error?.status == 401) {
				navigate('/login');
				window.location.reload();
			} else {
				toastText(
					'Something went wrong in fetching employee hours by period',
					'error'
				);
			}
		}
	};

	return (
		<>
			<div className={styles['customer-expense-main']}>
				<div className={styles['customer-expense-main__header']}>
					YTD Employee Hours
				</div>
				<div style={{ height: '100%' }}>
					<Bar
						onClick={handleOpen}
						options={{
							indexAxis: 'y' as const,
							elements: {
								bar: {
									borderWidth: 0,
									hoverBackgroundColor: '#9CB891',
								},
							},
							scales: {
								y: {
									ticks: {
										color: 'black',
										font: {
											size: 12,
										},
										maxTicksLimit: chartData.labels.length,
									},
									grid: {
										display: false,
									},
									beginAtZero: true,
								},
								x: {
									ticks: {
										callback: (tickValue) => {
											return `${formatNumberWithCommasV2(tickValue)}`;
										},
										color: 'black',
										font: {
											size: 12,
										},
									},
								},
							},
							responsive: true,
							plugins: {
								legend: {
									display: false,
								},
								tooltip: {
									enabled: true,
									callbacks: {
										label: (tooltipItem: any) => {
											return `${formatNumberWithCommasV2(tooltipItem.raw)}`;
										},
									},
								},
							},
						}}
						data={chartData}
					/>
				</div>
				{isModalOpen && (
					<GraphModal
						handleCancel={handleClose}
						isModalOpen={isModalOpen}
						chartData={chartData}
						chartType="barChart"
						title="employee-hours"
						displayTitle="Current fiscal year's employee hours"
					/>
				)}
			</div>
		</>
	);
};

export default EmployeeHours;
