/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, Input, Modal, Radio, Row, Select, TreeSelect } from 'antd';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { classAction } from 'redux/action/classAction';
import { customerOptionsAction } from 'redux/action/customerAction';
import { getEmployeeAction } from 'redux/action/employeeAction';
import { postApi, putApi } from 'redux/apis';
import { AppDispatch } from 'redux/store';
import { CloseSvg, DeleteActionSvg } from 'utils/svgs';
import { checkPermission, hasText, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { payPeriodOptionsAction } from 'redux/action/payPeriodAction';
import { getConfiguration } from 'redux/action/configurationAction';

interface Props {
	handleCloseModal: () => void;
	isModalOpen: boolean;
	modalData: any;
	getCustomRulesData: (value: string) => void;
	isCopy: boolean | null | undefined;
	modalKey: string;
}

const AddRuleModal = (props: Props) => {
	const {
		handleCloseModal,
		isModalOpen,
		modalData,
		getCustomRulesData,
		isCopy,
		modalKey,
	} = props;

	const { TextArea } = Input;
	const dispatch = useDispatch<AppDispatch>();

	const { optionData: customerList } = useSelector(
		(state: any) => state.customer
	);

	const { data: classList } = useSelector((state: any) => state.class);
	const { data: employeeList } = useSelector((state: any) => state.employees);

	const fetchData = () => {
		dispatch(classAction());
		dispatch(customerOptionsAction());
		dispatch(getEmployeeAction());
		dispatch(payPeriodOptionsAction());
	};

	const payPeriods = useSelector((state: any) => state?.payPeriods?.optionData);

	useEffect(() => {
		fetchData();

		let payPeriodId = localStorage.getItem('payPeriod');

		if (!payPeriodId) {
			if (payPeriods && payPeriods.length) {
				payPeriodId = payPeriods[payPeriods.length - 1].id;
			}
		}

		if (payPeriodId) {
			dispatch(
				getConfiguration({
					payPeriodId,
				})
			);
		}
	}, []);

	const [isLoading, setIsLoading] = useState(false);

	const [selectedEmployee, setSelectedEmployee] = useState('');
	const [data, setData] = useState<any>({
		name: '',
		description: '',
		isActive: true,
		triggerProcess:
			modalKey === '1'
				? 'split'
				: modalKey === '2'
				? 'edit'
				: 'delete' || 'split',
	});

	const [criteria, setCriteria] = useState({
		employeeId: 'ANY',
		classId: '',
		customerId: '',
		operator1: 'AND',
		operator2: 'AND',
	});

	const [actions, setActions] = useState([
		{
			key: Math.random(),
			employeeId: '',
			hours: 50,
			customerId: '',
			customerName: '',
			classId: '',
			className: '',
		},
		{
			key: Math.random(),
			employeeId: '',
			hours: 50,
			customerId: '',
			customerName: '',
			classId: '',
			className: '',
		},
	]);

	const [editActions, setEditActions] = useState({
		key: Math.random().toString(),
		employeeId: '',
		hours: '00:00',
		customerId: '',
		customerName: '',
		classId: '',
		className: '',
	});
	const [isEdit, setIsEdit] = useState(modalKey === '2' ? true : false);
	const [isSplit, setIsSplit] = useState(modalKey === '1' ? true : false);

	const [finalCustomerList, setFinalCustomerList] = useState<any>([]);
	const [isError, setIsError] = useState(false);
	const [isHoursError, setIsHoursError] = useState(false);
	const [total, setTotal] = useState(100);
	const [validation, setValidation] = useState({
		name: false,
		employeeId: false,
		classId: false,
		customerId: false,
	});
	const [editValidation, setEditValidation] = useState({
		employeeId: false,
		classId: false,
		customerId: false,
	});

	const [filteredClassList, setFilteredClassList] = useState([]);

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);
	const isAddRulePermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Custom Rules',
		permission: ['add'],
	});

	const isEditRulePermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Custom Rules',
		permission: ['edit'],
	});

	const { data: configurations } = useSelector(
		(state: any) => state?.configuration
	);

	const handleTrigger = (value: string) => {
		if (value === 'edit') {
			setIsEdit(true);
			setIsSplit(false);
		} else if (value === 'split') {
			setIsEdit(false);
			setIsSplit(true);
		} else if (value === 'delete') {
			setIsEdit(false);
			setIsSplit(false);
		}

		setData((prev: any) => {
			return {
				...prev,
				triggerProcess: value,
			};
		});
	};

	useEffect(() => {
		if (modalData) {
			setData({
				name: modalData?.name,
				description: modalData?.description,
				isActive: modalData?.isActive ? true : false,
				triggerProcess: modalData?.triggerProcess,
			});
			setCriteria(modalData.criteria);
			const employee = employeeList.find(
				(item: any) => item.id === modalData?.criteria?.employeeId
			);
			setSelectedEmployee(employee?.fullName);
			if (isEdit) {
				setEditActions(modalData.actions[0]);
			} else {
				setActions(modalData.actions);
			}
		}
	}, [modalData]);

	useEffect(() => {
		if (customerList && customerList.length > 0) {
			const newCustomers = [...customerList];
			newCustomers.shift();
			const finalData = [
				{
					value: '',
					title: 'Blank Customer',
					children: [],
				},
				{
					value: 'ANY',
					title: 'Any Customer',
					children: [],
				},
				...newCustomers,
			];
			setFinalCustomerList(finalData);
		}
	}, [customerList]);

	useEffect(() => {
		if (
			configurations &&
			configurations.settings &&
			classList &&
			classList.length
		) {
			const filteredClass = classList.filter((singleClass: any) => {
				return singleClass.Id !== configurations.settings[0].fields['f1'].value;
			});
			setFilteredClassList(filteredClass);
		}
	}, [configurations, classList]);

	const handleInput = (e: any) => {
		let value = e.target.value;
		if (e.target.name == 'name') {
			if (!hasText(value)) {
				setValidation((prev) => {
					return {
						...prev,
						name: true,
					};
				});

				setIsError(true);
			} else {
				setValidation((prev) => {
					return {
						...prev,
						name: false,
					};
				});

				setIsError(false);
			}
		}

		if (e.target.name == 'isActive') {
			value = e.target.checked;
		}

		setData((prev: any) => {
			return {
				...prev,
				[e.target.name]: value,
			};
		});
	};

	const handleCriteria = (name: string, value: any) => {
		if (name == 'employeeId') {
			if (!hasText(value)) {
				setValidation((prev) => {
					return {
						...prev,
						employeeId: true,
					};
				});
				setIsError(true);
			} else {
				setValidation((prev) => {
					return {
						...prev,
						employeeId: false,
					};
				});

				setIsError(false);
			}
		}
		setCriteria((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const handleOperator = (value: string, name: string) => {
		setCriteria((prev) => {
			const _query = {
				...prev,
				[name]: value,
			};

			return _query;
		});

		if (name === 'operator1' && value === 'NONE') {
			setCriteria((prev) => {
				return {
					...prev,
					customerId: '',
				};
			});
		}

		if (name === 'operator2' && value === 'NONE') {
			setCriteria((prev) => {
				return {
					...prev,
					classId: '',
				};
			});
		}
	};

	const addAnotherItem = () => {
		const length = actions.length + 1;
		const hours = Number(100 / length).toFixed(2);

		const diff = Number((100 - Number(hours) * length).toFixed(2));

		const lastHours = (
			diff && diff > 0 ? Number(hours) + diff : Number(hours) - Math.abs(diff)
		).toFixed(2);

		const data = {
			key: Math.random(),
			employeeId: '',
			hours: lastHours,
			customerId: '',
			customerName: '',
			classId: '',
			className: '',
		};

		const finalArr = actions.map((item: any) => {
			return {
				...item,
				hours: Number(hours),
			};
		});
		finalArr.push(data);
		setActions(finalArr);
	};

	const handleDeleteRow = (id: string) => {
		const _actions = JSON.parse(JSON.stringify(actions));
		const length = _actions.length - 1;
		const hours = Number(100 / length).toFixed(2);

		const diff = Number((100 - Number(hours) * length).toFixed(2));

		const lastHours = (
			diff && diff > 0 ? Number(hours) + diff : Number(hours) - Math.abs(diff)
		).toFixed(2);

		const finalArr = _actions.filter((action: any) => action.key !== id);
		finalArr.forEach((item: any, index: number) => {
			if (index == finalArr.length - 1) {
				item.hours = lastHours;
			} else {
				item.hours = hours;
			}
		});
		setActions(finalArr);
	};

	const handleActions = (
		name: string,
		value: string,
		key: string,
		nameLabel?: string,
		nameValue?: string
	) => {
		if (isEdit) {
			setEditActions((prev) => {
				const query: any = {
					...prev,
					[name]: value,
				};
				if (nameLabel && nameValue) {
					query[nameLabel] = nameValue;
				}
				return query;
			});
		} else {
			const _actions = actions;

			let _value: string | number = value;

			if (name === 'hours') {
				_value = value ? Number(value) : 0;
				const filtered = _actions.filter((item: any) => item.key !== key);
				let hours = 0;

				filtered.forEach((item) => {
					hours += Number(Number(item.hours).toFixed(2));
				});
				const totalHours = Number((Number(hours) + Number(_value)).toFixed(2));
				setTotal(totalHours);
				if (totalHours != 100) {
					setIsHoursError(true);
					setIsError(true);
				} else {
					setIsHoursError(false);
					setIsError(false);
				}
			}

			const finalArr = _actions.map((item: any) => {
				if (item.key === key) {
					const query = {
						...item,
						[name]: _value,
					};
					if (nameLabel && nameValue) {
						query[nameLabel] = nameValue;
					}
					return query;
				} else {
					return item;
				}
			});

			setActions(finalArr);
		}
	};

	const checkAllValidation = () => {
		let isValidation = true;
		if (data.name === '' && criteria.employeeId === '') {
			setValidation({
				...validation,
				name: true,
				employeeId: true,
			});
			isValidation = false;
		}

		if (data.name === '' && criteria.employeeId != '') {
			setValidation({
				...validation,
				name: true,
			});
			isValidation = false;
		}

		if (criteria.employeeId === '' && data.name != '') {
			setValidation({
				...validation,
				employeeId: true,
			});
			isValidation = false;
		}

		if (isEdit) {
			if (
				configurations.isClassRequiredForJournal &&
				editActions.classId === ''
			) {
				setEditValidation((prev) => {
					return {
						...prev,
						classId: true,
					};
				});

				isValidation = false;
			}
			if (
				configurations.isCustomerRequiredForJournal &&
				editActions.customerId === ''
			) {
				setEditValidation((prev) => {
					return {
						...prev,
						customerId: true,
					};
				});
				isValidation = false;
			}
		} else {
			setEditValidation({
				classId: false,
				customerId: false,
				employeeId: false,
			});
		}

		return isValidation;
	};

	const handleSubmit = async () => {
		try {
			const invalidation = checkAllValidation();
			if (invalidation) {
				setIsLoading(true);
				let response: any;
				if (modalData && !isCopy) {
					response = await putApi(`/custom-rule/${modalData.id}`, {
						companyId: localStorage.getItem('companyId'),
						...data,
						criteria: criteria,
						actions: isEdit ? [editActions] : actions,
					});
				} else {
					response = await postApi(`/custom-rule`, {
						companyId: localStorage.getItem('companyId'),
						...data,
						criteria: criteria,
						actions: isEdit ? [editActions] : actions,
					});
				}
				setIsLoading(false);
				toastText(response?.data?.message, 'success');
				handleCloseModal();
				getCustomRulesData(data?.triggerProcess);
			}
		} catch (err: any) {
			if (err.response.data.message) {
				toastText(err.response.data.message, 'error');
			} else {
				toastText('Something went wrong.', 'error');
			}
			setIsLoading(false);
		}
	};

	return (
		<div className={styles['rule-modal-container']}>
			<Modal
				open={isModalOpen}
				onCancel={handleCloseModal}
				className="preview-container"
				closable={false}
				style={{
					borderRadius: 0,
				}}
				footer={[]}
			>
				<div>
					<Row className={styles['preview-container__top']}>
						<div className={styles['preview-container__top-title']}>
							<b> {modalData && !isCopy ? 'Edit' : 'Add'} Custom Rules</b>
						</div>
						<div
							className={styles['preview-container__top-close']}
							onClick={handleCloseModal}
						>
							<CloseSvg />
						</div>
					</Row>
				</div>
				<div
					className={`${styles['preview-container__main']} preview-container__main`}
				>
					<div className={styles['preview-container__main-add-rule']}>
						<div className={styles['preview-container__main-title']}>
							{modalData && !isCopy ? 'Edit' : 'Add'} Rule
						</div>
						<div
							className={styles['preview-container__main-add-rule__content']}
						>
							<div className={styles['preview-container__main-input-multiple']}>
								<div
									className={styles['preview-container__main-input-container']}
								>
									<label className={styles['preview-container__main-label']}>
										Rule Name <span className="text-danger">*</span>
									</label>
									<Input
										className={`${styles['preview-container__main-input']} ${
											validation.name &&
											styles['preview-container__main-input-error']
										} ${styles['input-field']}`}
										value={data.name}
										name="name"
										onChange={handleInput}
									/>
								</div>
								<div
									className={`${styles['preview-container__main-input-container']} ${styles['preview-container__main-checkbox']}`}
								>
									<Checkbox
										checked={data.isActive}
										name="isActive"
										onChange={handleInput}
									/>
									<label className={styles['preview-container__main-label']}>
										Is Active
									</label>
								</div>
							</div>

							<div
								className={styles['preview-container__main-input-container']}
							>
								<label className={styles['preview-container__main-label']}>
									Description
								</label>
								<TextArea
									className={`${styles['preview-container__main-input ']} ${styles['input-field']}`}
									rows={2}
									value={data.description}
									name="description"
									onChange={handleInput}
									maxLength={300}
								/>
							</div>
						</div>
					</div>
					<div className={styles['preview-container__main-trigger-process']}>
						<div className={styles['preview-container__main-title']}>
							Trigger Process
						</div>
						<div className={styles['preview-container__main-second-title']}>
							Execute based on action
						</div>

						<div
							className={styles['preview-container__main-add-rule__content']}
						>
							<Radio.Group
								className={styles['preview-container__main-radio']}
								value={data.triggerProcess}
								onChange={(e) => handleTrigger(e.target.value)}
							>
								<Radio value={'split'}>Split</Radio>
								<Radio value={'delete'}>Delete</Radio>
								<Radio value={'edit'}>Edit</Radio>
							</Radio.Group>
						</div>
					</div>
					<div className={styles['preview-container__main-criteria']}>
						<div className={styles['preview-container__main-title']}>
							Criteria
						</div>
						<div
							className={styles['preview-container__main-add-rule__content']}
						>
							<div
								className={styles['preview-container__main-input-container']}
							>
								<label className={styles['preview-container__main-label']}>
									1. Employee Name <span className="text-danger">*</span>
								</label>
								<Select
									className={`${styles['preview-container__main-input']} ${
										validation.employeeId &&
										'preview-container__main-input-error'
									}`}
									value={criteria.employeeId}
									size="large"
									onChange={(value, data: any) => {
										handleCriteria('employeeId', value);
										setSelectedEmployee(data.children);
									}}
								>
									<Select.Option value="ANY">Any Employee</Select.Option>
									{employeeList?.map((employee: any, index: number) => {
										return (
											<Select.Option
												value={employee?.id}
												key={index}
											>
												{employee?.fullName}
											</Select.Option>
										);
									})}
								</Select>
							</div>
							<div
								className={styles['preview-container__main-input-container']}
							>
								<label
									className={styles['preview-container__main-label']}
								></label>
								<div
									className={`${styles['preview-container__main-input']} ${styles['preview-container__main-operator']}`}
								>
									<button
										className={`${
											styles['preview-container__main-operator-button']
										} ${
											criteria.operator1 === 'AND' &&
											styles['preview-container__main-operator-button-active']
										}`}
										onClick={() => handleOperator('AND', 'operator1')}
									>
										AND
									</button>
									<button
										className={`${
											styles['preview-container__main-operator-button']
										} ${
											criteria.operator1 === 'OR' &&
											styles['preview-container__main-operator-button-active']
										}`}
										onClick={() => handleOperator('OR', 'operator1')}
									>
										OR
									</button>
									{/* <button
										className={`${
											styles['preview-container__main-operator-button']
										} ${
											criteria.operator1 === 'NONE' &&
											styles['preview-container__main-operator-button-active']
										}`}
										onClick={() => handleOperator('NONE', 'operator1')}
									>
										NONE
									</button> */}
								</div>
							</div>
							<div
								className={styles['preview-container__main-input-container']}
							>
								<label className={styles['preview-container__main-label']}>
									2. Customer
								</label>
								<TreeSelect
									className={`${styles['preview-container__main-input']} ${
										validation.customerId &&
										'preview-container__main-input-error'
									}`}
									dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
									size="large"
									placeholder="Customer"
									value={criteria.customerId}
									showSearch={true}
									treeData={finalCustomerList}
									treeDefaultExpandAll
									onChange={(value: any) => handleCriteria('customerId', value)}
									filterTreeNode={(inputValue, treeNode) => {
										// Customize the filtering logic here
										return treeNode.props.title
											.toLowerCase()
											.includes(inputValue.toLowerCase());
									}}
								/>
							</div>
							<div
								className={styles['preview-container__main-input-container']}
							>
								<label
									className={styles['preview-container__main-label']}
								></label>
								<div
									className={`${styles['preview-container__main-input']} ${styles['preview-container__main-operator']}`}
								>
									<button
										className={`${
											styles['preview-container__main-operator-button']
										} ${
											criteria.operator2 === 'AND' &&
											styles['preview-container__main-operator-button-active']
										}`}
										onClick={() => handleOperator('AND', 'operator2')}
									>
										AND
									</button>
									<button
										className={`${
											styles['preview-container__main-operator-button']
										} ${
											criteria.operator2 === 'OR' &&
											styles['preview-container__main-operator-button-active']
										}`}
										onClick={() => handleOperator('OR', 'operator2')}
									>
										OR
									</button>
									{/* <button
										className={`${
											styles['preview-container__main-operator-button']
										} ${
											criteria.operator2 === 'NONE' &&
											styles['preview-container__main-operator-button-active']
										}`}
										onClick={() => handleOperator('NONE', 'operator2')}
									>
										NONE
									</button> */}
								</div>
							</div>
							<div
								className={styles['preview-container__main-input-container']}
							>
								<label className={styles['preview-container__main-label']}>
									3. Class
								</label>
								<Select
									className={`${styles['preview-container__main-input']} ${
										validation.classId && 'preview-container__main-input-error'
									}`}
									value={criteria.classId}
									size="large"
									onChange={(value) => handleCriteria('classId', value)}
								>
									<Select.Option value="">Blank Class</Select.Option>
									<Select.Option value="ANY">Any Class</Select.Option>
									{classList?.map((singleClass: any, index: number) => {
										return (
											<Select.Option
												value={singleClass?.Id}
												key={index}
											>
												{singleClass?.FullyQualifiedName}
											</Select.Option>
										);
									})}
								</Select>
							</div>
							<div
								className={styles['preview-container__main-input-container']}
							>
								<label className={styles['preview-container__main-label']}>
									Criteria Pattern
								</label>
								<p className={styles['preview-container__main-input']}>
									{criteria.employeeId && 1}{' '}
									{criteria.operator1 !== 'NONE' && `${criteria.operator1} 2`}{' '}
									{criteria.operator2 !== 'NONE' && `${criteria.operator2} 3`}
								</p>
							</div>
						</div>
					</div>
					{criteria?.employeeId && (
						<div className={styles['preview-container__main-actions']}>
							<div className={styles['preview-container__main-title']}>
								Actions
							</div>
							<div className={styles['preview-container__main-second-title']}>
								(Select one or more processes to be automatically triggered when
								a certain action is performed.)
							</div>
							{isSplit && (
								<div
									className={
										styles['preview-container__main-add-rule__content']
									}
								>
									<table className={styles['preview-container__main-table']}>
										<thead>
											<tr
												className={styles['preview-container__main-table-row']}
											>
												<th>Employee</th>
												<th>% of Hours</th>
												<th>Customers</th>
												<th>Class</th>
												{actions.length > 2 && <th>Action</th>}
											</tr>
										</thead>
										<tbody>
											{actions.map((action: any, index: number) => {
												return (
													<tr
														className={
															styles['preview-container__main-table-row']
														}
														key={action.key}
													>
														{!index ? (
															<td
																rowSpan={actions.length}
																style={{
																	width: '20%',
																}}
															>
																{selectedEmployee}
															</td>
														) : null}
														<td
															style={{
																width: '20%',
															}}
														>
															<NumericFormat
																value={action.hours}
																decimalScale={2}
																customInput={Input}
																fixedDecimalScale={true}
																suffix="%"
																className={`${
																	styles[
																		`${
																			isHoursError &&
																			'preview-container__main-input-error'
																		}`
																	]
																} ${styles['input-field']}`}
																onValueChange={(values: any) =>
																	handleActions(
																		'hours',
																		values.floatValue,
																		action.key
																	)
																}
															/>
														</td>
														<td
															style={{
																width: '30%',
															}}
														>
															<TreeSelect
																className={
																	styles['preview-container__main-input']
																}
																dropdownStyle={{
																	maxHeight: 400,
																	overflow: 'auto',
																}}
																size="large"
																placeholder="Customer"
																value={action.customerId}
																showSearch={true}
																treeData={finalCustomerList}
																treeDefaultExpandAll
																onChange={(value: any, data: any) => {
																	handleActions(
																		'customerId',
																		value,
																		action.key,
																		'customerName',
																		data[0]
																	);
																}}
																filterTreeNode={(inputValue, treeNode) => {
																	// Customize the filtering logic here
																	return treeNode.props.title
																		.toLowerCase()
																		.includes(inputValue.toLowerCase());
																}}
															/>
														</td>
														<td
															style={{
																width: '30%',
															}}
														>
															<Select
																className={
																	styles['preview-container__main-input']
																}
																value={action.classId}
																size="large"
																onChange={(value: any, data: any) => {
																	handleActions(
																		'classId',
																		value,
																		action.key,
																		'className',
																		data.children
																	);
																}}
															>
																{/* <Select.Option value="">
																	Select Class
																</Select.Option> */}
																<Select.Option value="">
																	Blank Class
																</Select.Option>
																{filteredClassList?.map(
																	(singleClass: any, index: number) => {
																		return (
																			<Select.Option
																				value={singleClass?.Id}
																				key={index}
																			>
																				{singleClass?.FullyQualifiedName}
																			</Select.Option>
																		);
																	}
																)}
															</Select>
														</td>
														{actions.length > 2 && (
															<td>
																<span
																	onClick={() => handleDeleteRow(action.key)}
																	className={
																		styles[
																			'preview-container__main-table-delete'
																		]
																	}
																>
																	<DeleteActionSvg />
																</span>
															</td>
														)}
													</tr>
												);
											})}
											<tr
												className={`${styles['preview-container__main-table-row']} ${styles['preview-container__main-table-end']}`}
											>
												<td></td>
												<td>Total {total}%</td>
												<td></td>
												<td>
													<span
														onClick={addAnotherItem}
														className={
															styles['preview-container__main-table-add']
														}
													>
														+ Add Another Item
													</span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							)}
							{isEdit && (
								<div
									className={
										styles['preview-container__main-add-rule__content']
									}
								>
									<table className={styles['preview-container__main-table']}>
										<thead>
											<tr
												className={styles['preview-container__main-table-row']}
											>
												<th>Employee</th>
												<th>Customers</th>
												<th>Class</th>
											</tr>
										</thead>
										<tbody>
											<tr
												className={styles['preview-container__main-table-row']}
												key={editActions?.key}
											>
												<td
													rowSpan={actions.length}
													style={{
														width: '20%',
													}}
												>
													{selectedEmployee}
												</td>
												<td
													style={{
														width: '30%',
													}}
												>
													<TreeSelect
														className={`${
															styles['preview-container__main-input']
														} ${
															editValidation.customerId &&
															'preview-container__main-input-error'
														}`}
														dropdownStyle={{
															maxHeight: 400,
															overflow: 'auto',
														}}
														size="large"
														placeholder="Customer"
														value={editActions?.customerId}
														showSearch={true}
														treeData={finalCustomerList}
														treeDefaultExpandAll
														onChange={(value: any, data: any) => {
															handleActions(
																'customerId',
																value,
																editActions?.key,
																'customerName',
																data[0]
															);
														}}
														filterTreeNode={(inputValue, treeNode) => {
															// Customize the filtering logic here
															return treeNode.props.title
																.toLowerCase()
																.includes(inputValue.toLowerCase());
														}}
													/>
												</td>
												<td
													style={{
														width: '30%',
													}}
												>
													<Select
														className={`${
															styles['preview-container__main-input']
														} ${
															editValidation.classId &&
															'preview-container__main-input-error'
														}`}
														value={editActions?.classId}
														size="large"
														onChange={(value: any, data: any) => {
															handleActions(
																'classId',
																value,
																editActions?.key,
																'className',
																data.children
															);
														}}
													>
														{/* <Select.Option value="">Select Class</Select.Option> */}
														<Select.Option value="">Blank Class</Select.Option>
														{filteredClassList?.map(
															(singleClass: any, index: number) => {
																return (
																	<Select.Option
																		value={singleClass?.Id}
																		key={index}
																	>
																		{singleClass?.FullyQualifiedName}
																	</Select.Option>
																);
															}
														)}
													</Select>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							)}
						</div>
					)}

					<div className={styles['preview-container__main-buttons']}>
						<button
							className={styles['preview-container__main-buttons-cancel']}
							onClick={handleCloseModal}
						>
							Cancel
						</button>
						{(isEditRulePermission || isAddRulePermission) && (
							<button
								className={`${styles['preview-container__main-buttons-save']} ${
									(isLoading || isError) && 'pointer-event-none'
								}`}
								onClick={handleSubmit}
							>
								{isLoading ? (
									<img
										src="/assets/gifs/loading-black.gif"
										height={40}
									/>
								) : modalData && !isCopy ? (
									'Update'
								) : (
									'Save'
								)}
							</button>
						)}
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default AddRuleModal;
