/* eslint-disable @typescript-eslint/no-unused-vars */
import { getApi, putApi } from 'redux/apis';
import Table from './Components/table';
import { hasText } from 'utils/utils';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useSelector } from 'react-redux';
import CustomConfigurationAddRuleModal from '../AddRule';

export const CustomMappingRuleTable = (props: any) => {

    const { payPeriodId, isModalOpen, handleModalClose } = props;

    const [modalData, setModalData] = useState(null);
    const [isCopy, setIsCopy] = useState<boolean | null | undefined>(false);

    const [searchValue, setSearchValue] = useState<string>('');
    const [debounceSearchValue] = useDebounce(searchValue, 1000);
    const [isSearchable, setIsSearchable] = useState(false);
    const [status, setStatus] = useState<string | null>(null);
    const [tableData, setTableData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const { selectedCompanyDetails } = useSelector(
        (state: any) => state.companies
    );

    const getCustomRulesData = async () => {
        setIsLoading(true);
        const query: any = {
            search: searchValue,
            status,
            payPeriodId,
            companyId: localStorage.getItem(
                'companyId'
            )
        };

        if (!hasText(searchValue)) {
            delete query.search;
        }

        if (!hasText(status)) {
            delete query.status;
        }

        try {
            const res = await getApi(
                `/custom-configuration-rule`,
                query
            );

            if (res.data?.data?.content) {
                setTableData(res.data.data.content)
            }

        } catch (error) {
            //
            console.log(error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getCustomRulesData();
    }, [selectedCompanyDetails, payPeriodId]);

    useEffect(() => {
        if (isSearchable) {
            getCustomRulesData();
        }
    }, [debounceSearchValue, isSearchable, selectedCompanyDetails, status]);

    const updatePriority = async (data: any) => {
        try {
            await putApi(
                `/custom-configuration-rule/update/priority?companyId=${localStorage.getItem(
                    'companyId'
                )}&payPeriodId=${payPeriodId}`,
                data
            );
        } catch (error) {
            //
            console.log(error);
        }
    };

    const handleOpen = (data?: any, _isCopy?: boolean) => {
        handleModalClose(true);
        setModalData(data);
        setIsCopy(_isCopy);
    };

    const handleCloseModal = () => {
        handleModalClose(false);
        setModalData(null);
    };


    return (
        <div>
            <Table
                onChangePriority={async (data: any) => {
                    await updatePriority(data);
                    await getCustomRulesData()
                }}
                getCustomRulesData={getCustomRulesData}
                openAddRuleModal={(data: any, isCopy: any) => handleOpen(data, isCopy)}
                data={tableData}
                isLoading={isLoading}
            />
            {isModalOpen && (
                <CustomConfigurationAddRuleModal
                    handleCloseModal={handleCloseModal}
                    isModalOpen={isModalOpen}
                    modalData={modalData}
                    getCustomRulesData={getCustomRulesData}
                    isCopy={isCopy}
                    payPeriodId={payPeriodId}
                />
            )}
        </div>
    )
}