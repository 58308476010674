import { Col, DatePicker, Row, Select, Space, Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	syncLogsAction,
	syncLogsPaginateAction,
} from 'redux/action/syncLogsAction';
import { AppDispatch } from 'redux/store';
import styles from './index.module.scss';
import './index.scss';
import moment from 'moment';

const { RangePicker } = DatePicker;

const SyncLogsTable = () => {
	const { Column } = Table;

	const dispatch = useDispatch<AppDispatch>();

	const { data, count } = useSelector((state: any) => state?.syncLogs);

	const [syncLogsData, setSyncLogsData] = useState([]);
	const [isInViewPort, setIsInViewPort] = useState<boolean>(false);
	const [pageNo, setPageNo] = useState(1);
	const [filterValue, setFilterValue] = useState<any>('');
	const [date, setDate] = useState<any>({
		fromDate: null,
		toDate: null,
	});

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);


	const getSyncLogsData = async (isPageable?: boolean) => {
		if (isPageable) {
			setPageNo((prev: any) => prev + 1);
		} else {
			setIsInViewPort(false);
			setPageNo(1);
		}

		const query: any = {
			page: isPageable ? pageNo + 1 : 1,
			limit: 10,
			filter: filterValue,
			fromDate: date?.fromDate,
			toDate: date?.toDate,
		};

		if (!filterValue || filterValue == '') {
			delete query['filter'];
		}

		if (!date.fromDate || !date.toDate) {
			delete query['fromDate'];
			delete query['toDate'];
		}

		if (isPageable) {
			await dispatch(syncLogsPaginateAction(query));
			return;
		}
		await dispatch(syncLogsAction(query));
	};

	useEffect(() => {
		getSyncLogsData();
	}, [filterValue, date, selectedCompanyDetails]);

	useEffect(() => {
		(async () => {
			if (isInViewPort && data.length < count) {
				await getSyncLogsData(true);
			}
		})();
	}, [isInViewPort]);

	const scrollHandler = useCallback((event: any) => {
		const { currentTarget } = event;
		const tableBody = currentTarget?.querySelector('tbody');
		if (
			tableBody?.getBoundingClientRect().top +
				tableBody.getBoundingClientRect().height <
			screen.height - 100
		) {
			setIsInViewPort(true);
		} else {
			setIsInViewPort(false);
		}
	}, []);

	const dateHandler = (value: any) => {
		if (value) {
			setDate({
				fromDate: value[0]['$d'],
				toDate: value[1]['$d'],
			});
		} else {
			setDate({
				fromDate: null,
				toDate: null,
			});
		}
	};

	useEffect(() => {
		const tableBody = document.querySelector('.ant-table-body');
		tableBody?.addEventListener('scroll', scrollHandler);
		return () => {
			// Remove scroll event listener when component unmounts
			tableBody?.removeEventListener('scroll', scrollHandler);
		};
	}, [data]);

	useEffect(() => {
		const syncData = data.map((singleLog: any) => {
			return {
				key: singleLog.id,
				date: singleLog.date,
				time: singleLog.time,
				module: singleLog.module,
				status: singleLog.status == 1 ? 'Success' : 'Failed',
				message: singleLog.message,
			};
		});
		setSyncLogsData(syncData);
	}, [data]);

	return (
		<div className={'dynamic-table dynamic-table-new'}>
			<div className={`${styles['search-filter']}`}>
				<div className={styles['search-filter__wrapper']}>
					<Row gutter={18}>
						<Col>
							<Space>
								<Select
									defaultValue="All"
									style={{ width: 200 }}
									onChange={(key: any, value: any) => {
										setFilterValue(value?.value);
									}}
									options={[
										{ label: 'All Modules', value: '' },
										{ label: 'Time Activity', value: 'Time Activity' },
										{ label: 'Employee', value: 'Employee' },
										{ label: 'Journal', value: 'Journal' },
									]}
									size="large"
								/>
							</Space>
						</Col>
						<Col>
							<Space direction="vertical" size={8}>
								<RangePicker
									size="large"
									onChange={dateHandler}
									format={'MM/DD/YYYY'}
								/>
							</Space>
						</Col>
					</Row>
				</div>
			</div>
			<Table
				dataSource={syncLogsData}
				pagination={false}
				scroll={{ y: '59vh', x: '63vh' }}
			>
				<Column
					title="Date"
					dataIndex="date"
					key="date"
					render={(value) => moment(value).format('MM/DD/YYYY')}
					className="bg-white"
					width="15%"
				/>
				<Column
					title="Time"
					dataIndex="time"
					key="time"
					render={(value) => moment(value).format('hh:mm A')}
					className="bg-white"
					width="15%"
				/>
				<Column
					title="Module"
					dataIndex="module"
					key="module"
					render={(value) => value}
					className="bg-white"
					width="15%"
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					render={(value) => {
						return (
							<div>
								<p className={styles[value]}>{value}</p>
							</div>
						);
					}}
					className="bg-white"
					width="15%"
				/>
				<Column
					title="Message"
					dataIndex="message"
					key="message"
					render={(value) => value}
					className="bg-white"
					width="40%"
				/>
			</Table>
		</div>
	);
};

export default SyncLogsTable;
