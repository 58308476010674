import { Button, Checkbox, Col, Modal, Row } from 'antd';
import { CloseSvg } from 'utils/svgs';
import styles from './index.module.scss';
import './index.scss';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

interface Props {
	isModalOpen: boolean;
	handleCancel: () => void;
	deleteOptionValue: boolean;
	handelChange: (value: boolean) => void;
	batchDeleteHandler: () => void;
	currentPayPeriod: any;
	isLoading: boolean;
	error: boolean;
}

const BatchDeleteModal = (props: Props) => {
	const {
		isModalOpen,
		handleCancel,
		deleteOptionValue,
		handelChange,
		batchDeleteHandler,
		currentPayPeriod,
		isLoading,
		error,
	} = props;

	const payPeriods = useSelector((state: any) => state?.payPeriods?.optionData);
	const [payPeriodIndex, setPayPeriodIndex] = useState(0);

	useEffect(() => {
		if (currentPayPeriod) {
			const index = payPeriods.findIndex(
				(singlePayPeriod: any) => singlePayPeriod.id === currentPayPeriod
			);
			setPayPeriodIndex(index > 0 ? index : 0);
			return;
		}
		setPayPeriodIndex(-1);
	}, [currentPayPeriod, payPeriods]);

	return (
		<div>
			<Modal
				open={isModalOpen}
				onCancel={handleCancel}
				closable={false}
				width={'600px'}
				footer={null}
				className="time-sheet-modal profile__popup"
			>
				<Row className={styles['time-sheet-modal__header']}>
					<div className="userDetailsTitle">
						<b>Delete selected time activities</b>
					</div>
					<div
						className={styles['time-sheet-modal__header-delete']}
						onClick={handleCancel}
					>
						<CloseSvg />
					</div>
				</Row>

				<hr />
				<div style={{ marginTop: '10px', padding: '1rem 3rem ' }}>
					<Checkbox
						checked={deleteOptionValue}
						onChange={() => {
							handelChange(!deleteOptionValue);
						}}
					>
						{`Remove all entries for selected pay period: ${moment(
							payPeriods[payPeriodIndex]?.startDate
						).format('MM/DD/YYYY')} - ${moment(
							payPeriods[payPeriodIndex]?.endDate
						).format('MM/DD/YYYY')}`}
					</Checkbox>
					<div className={styles['confirmation-card']}>
						<div className={styles['confirmation-note']}>
							<p className={styles['confirmation-text']}>
								{deleteOptionValue
									? `Are you sure you want to delete all entries for the selected pay period: ${moment(
											payPeriods[payPeriodIndex]?.startDate
									  ).format('MM/DD/YYYY')} - ${moment(
											payPeriods[payPeriodIndex]?.endDate
									  ).format('MM/DD/YYYY')}`
									: 'Are you sure you want to delete the selected Time activities?'}
							</p>
						</div>
					</div>
					{error && (
						<p className="error-message">
							An error occurred. Please try again.
						</p>
					)}
				</div>
				<hr />
				<div className="time_sheet_modal_footer">
					<Row
						justify="start"
						className={styles['time-sheet-modal__body__buttons']}
						gutter={16}
					>
						<Col
							xs={12}
							md={7}
							lg={7}
							sm={8}
						>
							<Button
								className={`${styles['time-sheet-modal__body__send-email']} ${
									isLoading && 'pointer-event-none'
								}`}
								block={true}
								onClick={() => batchDeleteHandler()}
							>
								{isLoading ? (
									<img
										src="/assets/gifs/loading-black.gif"
										height={40}
									/>
								) : (
									<div
										className={
											styles['time-sheet-modal__body__send-email-inside']
										}
									>
										<p>Delete</p>
									</div>
								)}
							</Button>
						</Col>
						<Col
							xs={12}
							md={7}
							lg={7}
							sm={8}
						>
							<Button
								className={styles['time-sheet-modal__body__send-email']}
								block={true}
								onClick={handleCancel}
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</div>
			</Modal>
		</div>
	);
};

export default BatchDeleteModal;
