import { Col, Row, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { payPeriodOptionsAction } from 'redux/action/payPeriodAction';
import { getApi } from 'redux/apis';
import { AppDispatch } from 'redux/store';
import {
	CostAllocation,
	EmployeeCost,
	// JournalEntries,
	QuickbooksEmployeeBlue,
	TimeLogs,
} from 'utils/svgs';
import styles from './index.module.scss';
import moment from 'moment';

export default function CostAllocationSummary() {
	const [summaryData, setSummaryData] = useState<any[]>([]);
	const [nextPayPeriod, setNextPayPeriod] = useState<any>();
	const [lastPayPeriod, setLastPayPeriod] = useState<any>();

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const payPeriods = useSelector((state: any) => state?.payPeriods?.optionData);

	useEffect(() => {
		try {
			dispatch(payPeriodOptionsAction());
			getGraphData();
		} catch (err: any) {
			if (err?.response?.data?.error?.status == 401) {
				navigate('/login');
				window.location.reload();
			}
		}
	}, [selectedCompanyDetails]);

	useEffect(() => {
		if (payPeriods && payPeriods.length) {
			const lastPayPeriod = payPeriods[payPeriods.length - 1];
			const nextDate = dayjs(lastPayPeriod.endDate).add(1, 'day');
			setLastPayPeriod(lastPayPeriod);
			setNextPayPeriod(nextDate);
		}
	}, [payPeriods]);

	const getGraphData = async () => {
		try {
			const res = await getApi('/dashboard/summary-by-payPeriod', {
				companyId: localStorage.getItem('companyId'),
			});

			if (res.data?.data) {
				setSummaryData(res.data.data);
			}
		} catch (error: any) {
			if (error?.response?.data?.error?.status == 401) {
				navigate('/login');
				window.location.reload();
			}
		}
	};

	return (
		<>
			<div className={styles['cost-allocation-main']}>
				<div className={styles['cost-allocation-main__header']}>
					Cost Allocation Summary
				</div>
				<div className={styles['cost-allocation-main__content']}>
					{payPeriods && payPeriods.length > 0 && (
						<Row className={styles['cost-allocation-main__content--row']}>
							<Col md={7}>
								{nextPayPeriod && `${nextPayPeriod.format('MM/DD/YYYY')} -`}
							</Col>
							<Col md={7}>
								<div
									className={`${styles['cost-allocation-main__content--row--link']}`}
								>
									<Link
										className={`${styles['cost-allocation-main__content--row--value']}`}
										to={`/employee-costs?payPeriodId=${lastPayPeriod?.id}&isNewPayPeriod=true`}
									>
										Begin Cost Allocation
									</Link>
								</div>
							</Col>
							<Col md={2}></Col>
							<Col md={2}></Col>
							<Col md={2}></Col>
						</Row>
					)}
					{summaryData.map((summary) => (
						<Row
							key={summary?.payPeriodId}
							className={styles['cost-allocation-main__content--row']}
						>
							<Col
								md={7}
								// className={styles['cost-allocation-main__content--row--title']}
							>
								{moment(summary?.payPeriodStartDate).format('MM/DD/YYYY')} -{' '}
								{moment(summary?.payPeriodEndDate).format('MM/DD/YYYY')}
							</Col>
							<Col md={7}>
								{summary?.isJournalPublished ? (
									<NumericFormat
										value={summary?.amount}
										thousandSeparator=","
										decimalScale={2}
										fixedDecimalScale={true}
										displayType="text"
										prefix="$"
										renderText={(value) => (
											<div
												className={`${styles['cost-allocation-main__content--row--link']}`}
											>
												<Link
													to={`/cost-allocations?payPeriod=${summary?.payPeriodId}`}
													className={`${styles['cost-allocation-main__content--row--value']}`}
												>
													<span style={{ marginRight: '5px' }}>
														{value ?? 0}
													</span>
													<QuickbooksEmployeeBlue />
												</Link>
											</div>
										)}
									/>
								) : (
									<div
										className={`${styles['cost-allocation-main__content--row--link']}`}
									>
										<Link
											className={`${styles['cost-allocation-main__content--row--value']}`}
											to={`/employee-costs?payPeriod=${summary?.payPeriodId}`}
										>
											In Progress
										</Link>
									</div>
								)}
							</Col>
							<Col
								md={2}
								// className={styles['cost-allocation-main__content--row--title']}
							>
								<Tooltip title="Employee Costs">
									<p
										className="employee-cost-pay-period"
										onClick={() =>
											navigate(
												`/employee-costs?payPeriodId=${summary?.payPeriodId}`
											)
										}
										style={{ textAlign: 'center' }}
									>
										<EmployeeCost />
									</p>
								</Tooltip>
							</Col>
							<Col
								md={2}
								// className={styles['cost-allocation-main__content--row--title']}
							>
								<Tooltip title="Time Activity">
									<p
										className="employee-cost-pay-period"
										onClick={() =>
											navigate(
												`/time-activity?payPeriod=${summary?.payPeriodId}`
											)
										}
										style={{ textAlign: 'center' }}
									>
										<TimeLogs />
									</p>
								</Tooltip>
							</Col>
							<Col
								md={2}
								// className={styles['cost-allocation-main__content--row--title']}
							>
								<Tooltip title="Cost Allocation">
									<p
										className="employee-cost-pay-period"
										onClick={() =>
											navigate(
												`/cost-allocations?payPeriod=${summary?.payPeriodId}`
											)
										}
										style={{ textAlign: 'center' }}
									>
										<CostAllocation />
									</p>
								</Tooltip>
							</Col>
						</Row>
					))}
				</div>
			</div>
		</>
	);
}
