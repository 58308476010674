import { createSlice } from '@reduxjs/toolkit';
import { getCostAllocationAction, getPaginationCostAllocationAction } from 'redux/action/costAllocationAction';
const initialState: any = {
    isLoading: true,
    isFirstTimeLoading: true,
    costAllocationData: [],
    hasCostAllocationData: false,
    employeeRowSpanMapping: {},
    currentDatePayPeriod: null,
    grandTotal: 0,
    error: null,
    count: 0,
};

const costAllocationSlice = createSlice({
    initialState,
    name: 'costAllocation',
    reducers: {
        clearCostAllocationRedux: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCostAllocationAction.pending, (state) => {
            state.isLoading = true;
            state.costAllocationData = null;
            state.employeeRowSpanMapping = null;
            state.currentDatePayPeriod = null;
            state.hasCostAllocationData = false;
        });
        builder.addCase(getCostAllocationAction.fulfilled, (state, action) => {
            state.costAllocationData = action.payload.result;
            state.count = action.payload.count;
            state.employeeRowSpanMapping = action.payload.employeeRowSpanMapping,
            state.currentDatePayPeriod = action.payload.currentDatePayPeriod
            state.grandTotal = action.payload.grandTotal
            state.isFirstTimeLoading = false;
            state.isLoading = false;
            state.error = null;
            state.hasCostAllocationData = true;
        });
        builder.addCase(getCostAllocationAction.rejected, (state: any, action) => {
            state.error = action.payload;
            state.isFirstTimeLoading = false;
            state.isLoading = false;
            state.costAllocationData = [];
            state.employeeRowSpanMapping = {};
            state.currentDatePayPeriod = null;
            state.count = 0;
            state.hasCostAllocationData = true;
        });
        //Paginate slice
        builder.addCase(getPaginationCostAllocationAction.fulfilled, (state: any, action) => {
            state.costAllocationData = [...state.costAllocationData, ...action.payload.result];
            state.employeeRowSpanMapping = { ...state.employeeRowSpanMapping, ...action.payload.employeeRowSpanMapping }
            state.grandTotal = state.grandTotal + action.payload.grandTotal;
            state.isLoading = false;
        });
    }
});

export default costAllocationSlice;
export const { clearCostAllocationRedux } = costAllocationSlice.actions;
