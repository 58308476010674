/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableActionHeader } from 'components/Global';
import { monthNames } from 'constants/Data';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMonthAfter12Months } from 'utils/utils';
import ActiveConnectionsTable from './ActiveConnectionsTable';
import Connection from './Connection';
import NewConnection from './NewConnection';
import styles from './index.module.scss';
// import NewConnectionModel from './NewConnectionModel';
import { getApi } from 'redux/apis';
import { BuySubscriptionButton } from 'components/Global/BuySubscriptionButton';

const Integrations = (props: any) => {
	const { setSelectedSidebar, openDrawerHandler } = props;

	const [isNewConnection, setIsNewConnection] = useState(true);
	const [isActiveConnectionTable, setIsActiveConnection] = useState(false);
	const [isConnection, setIsConnection] = useState(false);
	const [data, setData] = useState([]);

	// const [roleData, setRoleData] = useState<any>(null);

	const { data: companiesData } = useSelector((state: any) => state?.companies);

	const { data: userData } = useSelector((state: any) => state?.userProfile);

	// const [openNewConnectionModel, setOpenNewConnectionModel] = useState(false);

	const TimeAgo = (date: any) => {
		// const sourceTimeZone = 'America/Los_Angeles';
		const localDate = moment(date).utcOffset(
			new Date().getTimezoneOffset() * -1
		);
		const formattedTimeAgo = localDate.fromNow();

		return formattedTimeAgo;
		// return <span>{formattedTimeAgo}</span>;
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [subscriptionsData, setSubscriptionsData] = useState<any>(null);
	// const [adminCompanyCounts, setAdminCompanyCounts] = useState(0);

	// const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const data = companiesData
			?.map((companyObj: any) => {
				const formattedDate = TimeAgo(
					companyObj?.company?.employeeLastSyncDate
				);

				const date = getMonthAfter12Months(
					monthNames.indexOf(companyObj?.company?.fiscalYear) + 1
				);

				return {
					key: companyObj?.company?.id,
					accounting_software: '/assets/images/quickbooks.png',
					company: {
						title: companyObj?.company?.tenantName,
						year: `${companyObj?.company?.fiscalYear?.slice(0, 3)} -
				${date?.slice(0, 3)}`,
						currency: 'US Dollar',
					},
					connection_id: companyObj?.company?.tenantID,
					status: companyObj?.company?.status,
					last_sync: formattedDate || 'N/A',
					action: companyObj?.company?.isConnected,
					subscription: companyObj?.company?.Subscription[0],
					isCompanyAdmin: companyObj?.role?.isCompanyAdmin,
				};
				// if (companyObj?.company?.id === localStorage.getItem('companyId')) {
				// } else {
				// 	return;
				// }
			})
			.filter((companyObj: any) => companyObj !== undefined);

		setData(data);
	}, [companiesData]);

	// useEffect(() => {
	// 	if (companies && companies.length) {
	// 		const adminCompany = companies.filter((e: any) => e.role.isCompanyAdmin);
	// 		setAdminCompanyCounts(adminCompany.length);

	// 		// const selectedCompanyId = localStorage.getItem('companyId');

	// 		// if (selectedCompanyId) {
	// 		// 	const selectedCompanyData = companies.find(
	// 		// 		(e: any) => e.companyId === selectedCompanyId
	// 		// 	);

	// 		// 	// setRoleData(selectedCompanyData?.role);

	// 		// 	// if (selectedCompanyData &&
	// 		// 	// 	selectedCompanyData.company &&
	// 		// 	// 	selectedCompanyData.company.Subscription &&
	// 		// 	// 	selectedCompanyData.company.Subscription.length) {
	// 		// 	// 	setSubscriptionData(selectedCompanyData.company.Subscription[0])
	// 		// 	// 	const _subscriptionData = selectedCompanyData.company.Subscription[0];

	// 		// 	// 	let _addonQuantity = 0;

	// 		// 	// 	if (_subscriptionData && _subscriptionData.addons && _subscriptionData.addons.length) {

	// 		// 	// 		_subscriptionData.addons.forEach((addon: any) => {
	// 		// 	// 			_addonQuantity = _addonQuantity + addon.quantity
	// 		// 	// 		});
	// 		// 	// 	}

	// 		// 	// 	setAddonQuantity(_addonQuantity);

	// 		// 	// }
	// 		// }

	// 		getSubscriptions().catch(console.error);
	// 	}
	// }, [companies]);

	useEffect(() => {
		getSubscriptions().catch(console.error);
	}, [])

	const getSubscriptions = async () => {
		const res = await getApi('/subscription/logged-in', {
			companyId: localStorage.getItem('companyId'),
		});

		if (res.data.data && res.data.data.length) {
			const data = res.data.data.filter((e: any) => e.status === 'live' || e.status === 'trial');
			setSubscriptionsData(data);
		}
	};

	// const data = [
	// 	{
	// 		key: selectedCompanyDetails?.company?.id,
	// 		accounting_software: '/assets/images/quickbooks.png',
	// 		company: {
	// 			title: selectedCompanyDetails?.company?.tenantName,
	// 			year: `${selectedCompanyDetails?.company?.fiscalYear?.slice(0, 3)} -
	// 				${date?.slice(0, 3)}`,
	// 			currency: 'US Dollar',
	// 		},
	// 		connection_id: selectedCompanyDetails?.company?.tenantID,
	// 		status: selectedCompanyDetails?.company?.status,
	// 		last_sync: selectedCompanyDetails?.company?.employeeLastSyncDate || 'N/A',
	// 		action: selectedCompanyDetails?.company?.isConnected,
	// 	},
	// ];

	const newConnectionHandler = () => {
		setIsNewConnection(false);
		setIsConnection(true);
		setIsActiveConnection(false);
	};

	// const getHostedPaymentPage = async () => {
	// 	try {
	// 		setIsLoading(true);
	// 		const res = await getApi('/zoho/hosted-page', {
	// 			companyId: subscriptionsData[0].companyId,
	// 		});

	// 		if (res.data && res.data.data && res.data.data.hostedUrl) {
	// 			window.open(res.data.data.hostedUrl, '_blank');
	// 		}
	// 	} catch (error: any) {
	// 		toastText(error.message, 'error');
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };

	return (
		<div>
			<div className="">
				<TableActionHeader title={'Integrations'}>
					{/* {subscriptionsData && roleData?.isCompanyAdmin ? (
						subscriptionsData.length > adminCompanyCounts ? (
							<button
								className="btn-black"
								onClick={() => setOpenNewConnectionModel(true)}
							>
								Add New Company
							</button>
						) : (
							<button
								className="btn-black"
								onClick={() => getHostedPaymentPage()}
							>
								{isLoading ? (
									<img src="assets/gifs/loading-black.gif" height={30} />
								) : (
									'Buy New Subscription To Add Company'
								)}
							</button>
						)
					) : null} */}
					{
						!userData.isSignupViaQuickBooks || userData.companies.length ? (
							<BuySubscriptionButton />
						) : null
					}

				</TableActionHeader>
				{(isNewConnection || isActiveConnectionTable) && (
					<div className={styles['main-container']}>
						{companiesData?.length == 0 ? (
							<NewConnection setIsNewConnection={newConnectionHandler} hasSubscription={subscriptionsData?.length ? true : false} />
						) : (
							<ActiveConnectionsTable
								data={data}
								setSelectedSidebar={setSelectedSidebar}
								openDrawerHandler={openDrawerHandler}
							/>
						)}
					</div>
				)}
				{isConnection && <Connection />}
			</div>
			{/* <NewConnectionModel
				open={openNewConnectionModel}
				onCancel={() => setOpenNewConnectionModel(false)}
			/> */}
		</div>
	);
};

export default Integrations;
