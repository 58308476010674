import { TableActionHeader } from 'components/Global';
import Table from './Table/index';

const SyncLogs = () => {
	return (
		<div>
			<TableActionHeader title={'Sync Logs'}></TableActionHeader>
			<Table />
		</div>
	);
};

export default SyncLogs;
