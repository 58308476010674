import React from 'react';
import styles from './index.module.scss';
import ReportsMain from 'components/Reports/ReportsMain';

// interface Props {}

const Reports = () => {
	return (
		<div className={styles['reports-container']}>
			<div className={styles['reports-container__header']}>
				<div className={styles['reports-container__header--title']}>
					Reports
				</div>
			</div>
			<div className={styles['reports-main']}>
				<ReportsMain />
			</div>
		</div>
	);
};

export default Reports;
