import { Tabs } from 'antd';
import { TableActionHeader } from 'components/Global';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCustomRulesAction,
	getDeleteCustomRulesAction,
	getEditCustomRulesAction,
	getSplitCustomRulesAction,
} from 'redux/action/customRuleAction';
import { AppDispatch } from 'redux/store';
import { checkPermission } from 'utils/utils';
import AddRuleModal from './AddRuleModal';
import DeleteRules from './DeleteRules';
import EditRules from './EditRules';
import SplitRules from './SplitRules';
import styles from './index.module.scss';
import { AddSvg } from 'utils/svgs';

const CustomRules = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalData, setModalData] = useState(null);
	const [selectedRules, setSelectedRules] = useState('1');

	const [isCopy, setIsCopy] = useState<boolean | null | undefined>(false);

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	// for check is there add permission
	const isAddRulePermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Custom Rules',
		permission: ['add'],
	});

	const dispatch = useDispatch<AppDispatch>();

	const getCustomRulesData = async (triggerProcess: string) => {
		const query: any = {
			search: '',
		};

		switch (triggerProcess) {
			case 'split':
				await dispatch(getSplitCustomRulesAction(query));
				setSelectedRules('1');
				break;
			case 'edit':
				await dispatch(getEditCustomRulesAction(query));
				setSelectedRules('2');
				break;
			case 'delete':
				await dispatch(getDeleteCustomRulesAction(query));
				setSelectedRules('3');
				break;
			default:
				await dispatch(getCustomRulesAction(query));
		}
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setModalData(null);
	};

	const handleOpen = (data?: any, _isCopy?: boolean) => {
		setIsModalOpen(true);
		setModalData(data);
		setIsCopy(_isCopy);
	};

	const items = [
		{
			key: '1',
			label: 'Split Rules',
			children: (
				<SplitRules
					handleOpen={handleOpen}
					modalData={modalData}
				/>
			),
		},
		{
			key: '2',
			label: 'Edit Rules',
			children: (
				<EditRules
					handleOpen={handleOpen}
					modalData={modalData}
				/>
			),
		},
		{
			key: '3',
			label: 'Delete Rules',
			children: (
				<DeleteRules
					handleOpen={handleOpen}
					modalData={modalData}
				/>
			),
		},
	];

	return (
		<div>
			<TableActionHeader title={'Custom Rules'}>
				{isAddRulePermission && (
					<button
						className={styles['add-rule-button']}
						onClick={() => handleOpen(null)}
					>
						<AddSvg />
						<p>Add Rule</p>
					</button>
				)}
			</TableActionHeader>

			<div className={styles['custom__rules-tabs']}>
				<Tabs
					defaultActiveKey="1"
					items={items}
					tabBarStyle={{
						paddingLeft: '20px',
						fontSize: '18px',
					}}
					activeKey={selectedRules}
					onChange={(e) => {
						// setModalKey(e === '1' ? 'split' : e === '2' ? 'edit' : 'delete');
						setSelectedRules(e);
					}}
				/>
			</div>
			{isModalOpen && (
				<AddRuleModal
					handleCloseModal={handleCloseModal}
					isModalOpen={isModalOpen}
					modalData={modalData}
					getCustomRulesData={getCustomRulesData}
					isCopy={isCopy}
					modalKey={selectedRules}
				/>
			)}
		</div>
	);
};

export default CustomRules;
