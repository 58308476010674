import { Button } from 'antd'; // Import the Button component from Ant Design
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { navigateWithPermissions } from 'utils/utils';
import { useSelector } from 'react-redux';

const Unauthorized = (props: any) => {
	const { status, title } = props;

	const navigate = useNavigate();
	const [secondsRemaining, setSecondsRemaining] = useState(10);
	const [path, setPath] = useState('/');

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	useEffect(() => {
		if (selectedCompanyPermission) {
			const path: string = navigateWithPermissions(selectedCompanyPermission);
			setPath(path);
		}
	}, [selectedCompanyPermission]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setSecondsRemaining((prevSeconds) => {
				if (prevSeconds === 1) {
					clearInterval(intervalId);
					navigate(path);
				}
				return prevSeconds - 1;
			});
		}, 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, [navigate, path]);

	return (
		<div id="notfound">
			<div className="notfound">
				<div className="notfound-error">
					<h1>{status}</h1>
					<h2>{title}</h2>
				</div>
				<Button
					type="primary"
					className="returnLogin"
					onClick={() => {
						const path: string = navigateWithPermissions(
							selectedCompanyPermission
						);
						navigate(path);
					}}
				>
					Go Back
				</Button>
				<p className="goback-text">
					You will be automatically redirected to the dashboard{' '}
					{secondsRemaining} {secondsRemaining === 1 ? 'second' : 'seconds'}
				</p>
			</div>
		</div>
	);
};

export default Unauthorized;
